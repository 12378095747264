import axios from 'axios'
import io from "socket.io-client";

import { 
  LISTEN_SESSIONS_START,
  LISTEN_SESSIONS_UPDATE,
  LISTEN_SESSIONS_CREATE,
  LISTEN_SESSIONS_DELETE,
  FETCH_SESSIONS,
  CREATE_SESSION,
  CREATE_SESSION_LOG,
  UPDATE_SESSION_STATUS,
  UPDATE_SESSION_SAVE_STATUS,
  UPDATE_FETCHED_SESSIONS_STATUS
} from './types';
import { getAPI } from '../helpers';

const api = getAPI();
const { REACT_APP_IS_DEV  } = process.env;

let socket = {}; // Declare a variable to hold the Socket.IO client instance
const listenToEvent = (hostname, eventName, updateType) => (dispatch) => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  console.log('listenToEvent', eventName, updateType);
  socket[eventName] = io(baseURL);
  const startType = updateType + '_START';
  const payload = 1;
  dispatch({ type: startType, payload: 1 });
  socket[eventName].on(`${eventName}-updated`, (res) => {
    console.log(startType, "event", res);
    let type, payload;
    if (res.new_val != null) {
      type = updateType + (res.old_val != null ? '_UPDATE' : '_CREATE');
      payload = res.new_val;
    } else {
      type = updateType + '_DELETE';
      payload = res.old_val;
    }
    dispatch({ type, payload })
  });
};

const listenToEventStop = (eventName, updateType) => (dispatch) => {
  if (socket[eventName]) {
    socket[eventName].off(`${eventName}-updated`);
    socket[eventName].disconnect();
    socket[eventName] = null;
  }
  const type = updateType + '_START';
  dispatch({ type, payload: 0 });
}

// Events
export const listenSessions = (hostname) =>
  listenToEvent(hostname, 'management_sessions', 'LISTEN_SESSIONS');
export const listenSessionsStop = () =>
listenToEventStop('management_sessions', 'LISTEN_SESSIONS');

export const fetchSessions = (hostname) => async dispatch => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  try {
    const response = await axios.get(baseURL + '/management/sessions/');
    dispatch({
      type: FETCH_SESSIONS,
      payload: response.data
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateListenSessionsStart = (payload) => dispatch => {
  dispatch({
    type: LISTEN_SESSIONS_START,
    payload
  })
};
export const updateSessionsFetchedStatus = (payload) => dispatch => {
  dispatch({
    type: UPDATE_FETCHED_SESSIONS_STATUS,
    payload
  })
};
export const createSession = (hostname, sessionData) => async dispatch => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  try {
    await axios.patch(baseURL + '/management/sessions/', sessionData);
    // only fire when it's a signIn not a signOut
    if (sessionData.status) {
      dispatch({
        type: CREATE_SESSION,
        payload: 1
      });
    }
  } catch (error) {
    console.log(error);
  }
};

//update status whether session data in the app is valid or not
export const updateSessionStatus = (payload) => dispatch => {
  dispatch({
    type: UPDATE_SESSION_STATUS,
    payload
  })
};
export const updateSessionSaveStatus = (payload) => dispatch => {
  dispatch({
    type: UPDATE_SESSION_SAVE_STATUS,
    payload
  })
};
