import React from 'react';
import { connect } from 'react-redux'

import Time from '../time/Time'
import TimeFormatter from '../time/TimeFormatter'
class TicketDetails extends React.Component {
    render(){
        const { ticket, isActive } = this.props
        const { services, callTickets } = this.props

        if( !services.length ){  return <p>Loading services</p> }
        if(ticket == null ) { return <p>No tickets available</p> }
        
        var service = services.find( data => data.id === ticket.serviceId )
        var serviceName = service != null
            ?   service.name 
            :   null

        return (<div>
                <div className="informationContainer">
                    <div className="informationRow">
                        <label>Ticket Number</label>
                        <span>{ticket.ticketLetter}{ticket.ticketNumber}</span>
                    </div>
                    <div className="informationRow">
                        <label>Service</label>
                        <span>{serviceName}</span>
                    </div>
                    <div className="informationRow">
                        <label>Total In-branch Time</label>
                        <span><Time created = { ticket.created } /></span>
                    </div>
                    <div className="informationRow">
                        <label>Customer Arrival Time</label>
                        <span><TimeFormatter created = { ticket.created } /></span>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { tickets, services, auth } = state
    return {
        tickets: tickets.tickets,
        callTickets: tickets.callTickets,
        activeTicketId: tickets.activeTicketId,
        authenticatedUser: auth.authenticatedUser,
        services: services.services,
    }
}

export default connect(mapStateToProps, {})(TicketDetails);