import React from "react";
import { connect } from 'react-redux'

// components
import Sidebar from "./queuing/Sidebar";
import Content from "./queuing/Content";
// helpers
import ActiveTicketHelper from "./queuing/helpers/ActiveTicketHelper";
import NewTicketHelper from "./queuing/helpers/NewTicketHelper";
import TransferTicketHelper from "./queuing/helpers/TransferTicketHelper";

class Queuing extends React.Component {

  render() {
    return (
      <div className="wrapper">

        <ActiveTicketHelper />
        <NewTicketHelper />
        <TransferTicketHelper />

        <Sidebar />
        <Content children={this.props.children} />
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {}
}

export default connect(mapStateToProps, null)(Queuing);
