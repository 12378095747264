
import {
  SAVE_WINDOW_FOCUS
} from './types';

export const saveWindowFocus = (status) => dispatch => {
    dispatch({
      type: SAVE_WINDOW_FOCUS,
      payload: status
    })
}