import React from "react";
import { connect } from 'react-redux'
import addNotification from "react-push-notification";
import { updateActiveTicket } from '../../../store/actions/ticketsActions'

class TransferTicketHelper extends React.Component {
    componentDidUpdate(prevProps, prevState, snapshot){
        let { authenticatedUser, listenTransferTicketsCount, transferTickets, users, supportDeskId } = this.props

        if ( prevProps.transferTickets.length < transferTickets.length && listenTransferTicketsCount !== 0 ) {

            const latestTicket = transferTickets[ transferTickets.length - 1 ]
            const { ticket, transferAgent } = latestTicket
            const agent = users.find( data => data.id === transferAgent)

            let pushMessage = ticket.manage != null
                // check transfers to userId
                ?   ticket.manage.userId != null
                        ?   ticket.manage.userId === authenticatedUser.id
                            ?   1
                            :   0
                        // check transfers to supportDeskId
                        :   ticket.manage.supportDeskId != null
                            ?   ticket.manage.supportDeskId === supportDeskId
                                ?   2
                                :   0
                            :   0
                :   0

            
            const message = pushMessage
                ?   pushMessage === 1
                    ?   'Ticket was transferred to you by: ' + agent.name + " " + agent.lastname
                    :   'Ticket was transferred to your supportDesk by: ' + agent.name + " " + agent.lastname
                :   'Ticket was transferred to your service by: ' + agent.name + " " + agent.lastname
            
            addNotification({
                title: 'New Transfer: '+ ticket.ticketLetter +'-'+ ticket.ticketNumber,
                message,
                native: true 
            });
        }
    }

    render() {
        return null
    }
}

const mapStateToProps = state => {
    const { users, tickets, sessions, auth } = state
    return {
        listenTransferTicketsCount: tickets.listenTransferTicketsCount,
        transferTickets: tickets.transferTickets,
        activeTicketId: tickets.activeTicketId,
        authenticatedUser: auth.authenticatedUser,
        users: users.users,
        sessions: sessions.sessions,
        supportDeskId: auth.supportDeskId,
    }
}

export default connect(mapStateToProps, { 
  updateActiveTicket,
})(TransferTicketHelper);
