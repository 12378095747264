import { 
  SAVE_WINDOW_FOCUS,
} from '../actions/types';

const initialState = {
  errorNotificationId: null,
  windowFocusStatus: true,
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    
    case SAVE_WINDOW_FOCUS:
      return {
        ...state,
        windowFocusStatus: action.payload
      };

    default:
      return state;
  }
}