import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom'

import { updateTokenStatus } from '../../../../store/actions/authActions';
import { createSession } from '../../../../store/actions/sessionsActions';
// components

class SignOut extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            reason: '',
            description: '',
            error: 0,
        }
        this.signOut = this.signOut.bind(this);
    }

    onChange(e) { this.setState({ [e.target.name]: e.target.value }) }
    signOut(e){
        e.preventDefault();
        const { authenticatedUser, supportDeskId, organizationId, branchId } = this.props
        const { id } = authenticatedUser

        const organization = this.props.organizations.find(a => a.id === this.props.organizationId)
        const branch = this.props.branches.find(a => a.id === this.props.branchId)

        const { reason, description } = this.state
        const sessionData = {
            userId: id,
            id,
            supportDeskId,
            status: 0,
            reason,
            description,
            organizationId: organizationId,
            branchId: branchId
        }
        this.props.createSession(this.props.hostname, sessionData)
        this.props.updateTokenStatus(-1);
    }

    render(){
        const { fbOptions } = this.props
        if(fbOptions== null) return null
        return (
            <div className="home-center-content1 home-center-content2">
                <div className="titleBlock">

                    <h4>Sign Out</h4>
                </div>
                <form onSubmit={this.signOut}>
                    <div className="select-options">
                        <label>Reason for logout</label>
                        <div className="selectStyling">
                            <select name="reason" value={this.state.reason} onChange={(e) => this.onChange(e) }>
                                <option value="">Select an option</option>
                                {
                                    fbOptions
                                        .filter(data => data.visibility )
                                        .filter(data => data.optionTypeId === 'IfuMt4ahtNHK46zTP1Jl')
                                        .map((data, index) => 
                                            <option key={index} value={ data.id }>{ data.name }</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                    <div className="input-options">
                        <label>Got more to add?</label>
                        <textarea placeholder="Type here"  name="description" value={this.state.description} onChange={(e) => this.onChange(e) }></textarea>
                    </div>
                    <button type="submit" className="form-submit-btn">Submit</button>
                </form>
            </div>
        )
    }
}

SignOut.propTypes = {
    updateTokenStatus: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    const { auth, organizations, branches, fb } = state
    return {
        hostname: auth.hostname,
        authenticatedUser: auth.authenticatedUser,
        organizations: organizations.organizations,
        organizationId: auth.organizationId,
        branchId: auth.branchId,
        branches: branches.branches,
        supportDeskId: auth.supportDeskId,
        fbOptions: fb.fbOptions,
        fbOptionTypes: fb.fbOptionTypes,
    }
}

export default connect(mapStateToProps, { updateTokenStatus, createSession })(SignOut);