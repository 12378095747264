import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom'

// this component is used for exploriing users and checking their availability. This is not used for transfers.
class AgentsComponent extends React.Component {
    render(){
        const { users, sessions, supportDesks, fbOptions } = this.props

        var onlineAgents = []
        var offlineAgents = []
        
        users.forEach(data => {
            var session = sessions.find( session => (session.userId === data.id))
            if(session!=null){
                if( Number(session.status)===1 ){
                    var supportDesk = supportDesks.find(data => data.id === session.supportDeskId)
                    var supportDeskType = supportDesk.type
                    var supportDeskNumber = supportDesk.number != null ? supportDesk.number : ""
                    onlineAgents.push({ ...data, supportDeskId: session.supportDeskId, supportDeskType, supportDeskNumber })
                } else {
                    offlineAgents.push({ ...data, reason: session.reason })
                }
            } else {
                offlineAgents.push({ ...data, reason: ""})
            }
        })
        
        return(
            <div className="home-left-content5-wraper">
                <div className="titleBlock">
                    <h4>{onlineAgents.length} Agent { onlineAgents.length !== 1 ? 's' : '' } Online</h4>
                </div>
                <div className="usersListContainer">
                    <div className="user-list-online">
                    {onlineAgents.map((data, index) => {

                        var inititals = data.name
                        .replace(/[&\/\\#,+()$~%.'":*?!<>{}]/g,'')
                        .charAt(0) 
                        + 
                        data.lastname
                        .replace(/[&\/\\#,+()$~%.'":*?!<>{}]/g,'')
                        .charAt(0)
                        
                        return(
                            <div className="user-list" key={index}>
                                <div className="user-name-and-pic">
                                    <div>
                                        <div>
                                            <span className="status-badge"></span>
                                            <span className="user-image-text">{ inititals }</span> 
                                        </div>
                                        <p>{ data.name + " " + data.lastname}</p>
                                    </div>
                                </div>
                                <div className="user-list-content"><p>{ data.supportDeskType + " " + data.supportDeskNumber }</p></div>
                            </div>
                        )
                        })}
                    </div>

                    <div className="user-list-offline">
                    {offlineAgents.map((data, index) => {

                        var inititals = data.name
                        .replace(/[&/\\#,+()$~%.'":*?!<>{}]/g,'')
                        .charAt(0) 
                        + 
                        data.lastname
                        .replace(/[&/\\#,+()$~%.'":*?!<>{}]/g,'')
                        .charAt(0)

                        return(
                            <div className="user-list" key={index}>
                                <div className="user-name-and-pic">
                                    <div>
                                        <div>
                                            <span className="status-badge"></span>
                                            <span className="user-image-text">{ inititals }</span> 
                                        </div>
                                        <p>{ data.name + " " + data.lastname}</p>
                                    </div>
                                </div>
                                <div className="user-list-content"><p>
                                { 
                                    data.reason.length
                                        ?   fbOptions.find( options => options.id === data.reason ).name
                                        :   "Never signed in"
                                }
                                </p></div>
                            </div>
                        )
                        })}
                    </div>

                </div>
                

            </div>
        )
    }
}

const mapStateToProps = state => {
    const { auth, users, misc, sessions, services } = state
    return {
        users: users.users,
        authenticatedUser: auth.authenticatedUser,
        sessions: sessions.sessions,
        supportDesks: services.supportDesks,
        fbOptions: misc.fbOptions
    }
};
  
export default connect(mapStateToProps, {})(AgentsComponent);