import axios from 'axios'
import dayjs from 'dayjs'
import { toast } from "react-toastify";
import io from "socket.io-client";
import store from '../index';

import { 
  LISTEN_BRANCHES_START,
  LISTEN_BRANCHES_UPDATE,
  LISTEN_BRANCHES_CREATE,
  LISTEN_BRANCHES_DELETE,
  FETCH_BRANCHES,
  RESET_BRANCH,
  UPDATE_BRANCH_KEY_VALUE,
  UPDATE_BRANCH_GET_OBJECT
} from './types';

import { getAPI, toastActions } from '../helpers';

const api = getAPI();
const { REACT_APP_IS_DEV  } = process.env;

let socket = {}; // Declare a variable to hold the Socket.IO client instance
const listenToEvent = (hostname, eventName, updateType, organizationCheck = 0) => (dispatch) => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  console.log(hostname, baseURL);
  socket[eventName] = io(baseURL);
  const startType = updateType + '_START';
  const payload = 1;
  dispatch({ type: startType, payload: 1 });
  socket[eventName].on(`${eventName}-updated`, (res) => {
    console.log(startType, "event", res);
    let type, payload;
    if (res.new_val != null) {
      type = updateType + (res.old_val != null ? '_UPDATE' : '_CREATE');
      payload = res.new_val;
    } else {
      type = updateType + '_DELETE';
      payload = res.old_val;
    }

    // dispatch only if the payload is for the active organization
      dispatch({
        type,
        payload
      })
  });
};

const listenToEventStop = (eventName, updateType) => (dispatch) => {
  if (socket[eventName]) {
    socket[eventName].off(`${eventName}-updated`);
    socket[eventName].disconnect();
    socket[eventName] = null;
  }
  const type = updateType + '_START';
  dispatch({ type, payload: 0 });
}

// Events
export const listenBranches = (hostname) =>
  listenToEvent(hostname, 'admin_branches', 'LISTEN_BRANCHES', 1);
export const listenBranchesStop = () =>
listenToEventStop('admin_branches', 'LISTEN_BRANCHES');

export const fetchBranches = (hostname, organizationId) => async (dispatch) => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  try {
    const res = await axios.get(baseURL + "/admin/branches", { params: { organizationId } });
    dispatch({
      type: FETCH_BRANCHES,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};
