import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom'

// components
import MyServices from './sidebar/MyServices';
import { version } from '../../store/constants';

class Sidebar extends React.Component {
    render(){
        const { authenticatedUser, supportDesks, supportDeskId } = this.props;
        const supportDesk = supportDesks.find(data => data.id === supportDeskId );
        return (
            <nav id="sidebar">
                <div className="sidebar-header">
                    <div className="sidebar-user">
                        <div className="user-image">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
                                <circle cx="10" cy="6" r="5" stroke="#0A0914" strokeWidth="1.527"/>
                                <path stroke="#0A0914" strokeWidth="1.527" d="M19 20c0-4.97-4.03-9-9-9s-9 4.03-9 9"/>
                            </svg>
                        </div>
                        <div className="user-name">
                            <h4>
                                {
                                    authenticatedUser.name != null
                                    ? ' ' + authenticatedUser.name
                                    : ''
                                }

                                {
                                    authenticatedUser.lastname != null
                                    ?   ' ' + authenticatedUser.lastname
                                    :   ''
                                }
                            </h4>
                            <div className="supportDesk">
                            {
                                supportDesk!=null
                                ?   supportDesk.type
                                : ""
                            }
                            {
                                supportDesk!=null
                                ?   supportDesk.number != null
                                    ?  " " +  supportDesk.number
                                    : ''
                                : ''
                            }
                            </div>
                        </div>
                    </div>
                </div>

                <ul className="list-unstyled components">
                    <li className="active">
                        <NavLink to='/queuing/tickets/'>
                            <ion-icon name="home-outline"></ion-icon>
                            Home
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/queuing/analytics/'>
                            <ion-icon name="bar-chart-outline"></ion-icon>
                            Analytics
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/queuing/agents/'>
                            <ion-icon name="people-outline"></ion-icon>
                            Agents
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/sign-out/'>
                            <ion-icon name="log-out-outline"></ion-icon>
                            Sign Out
                        </NavLink>
                    </li>
                    {/* version */}
                    <li>

                        <div style={{color:"#8B8C94", width: "100%", textAlign: "center", marginTop: 20, fontSize: "14px"}}>Version { version }</div>
                    </li>
                    {/* <li>
                        <NavLink to='/sign-in/'>
                            <ion-icon name="log-in-outline"></ion-icon>
                            Sign In
                        </NavLink>
                    </li> */}
                    {/* <li>
                        <NavLink to='/components/'>
                            <ion-icon name="apps-outline"></ion-icon>
                            Components
                        </NavLink>
                    </li> */}
                
                </ul>

                <MyServices />
            </nav>
        )
    }
}
const mapStateToProps = state => {
    return {
        authenticatedUser: state.auth.authenticatedUser,
        supportDesks: state.services.supportDesks,
        supportDeskId: state.auth.supportDeskId 
    }
}
  
export default connect(mapStateToProps, {})(Sidebar);

