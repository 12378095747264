import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { loadState } from './LocalStorage';
const middleware = [thunk];

const persistedState = loadState();

var initialState = persistedState != null
? persistedState
: {};

if(initialState.tickets != null ){
  var tickets = initialState.tickets;
  
  // get the first second of the day
  var now = new Date()
  var startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  startOfDay /= 1000
  
  // remove old tickets
  const ticketsForToday = tickets.tickets.filter(ticket => 
    ticket.created >= startOfDay
  )
  tickets.tickets = ticketsForToday
  
  const activeTicket = tickets.tickets.find( ticket => 
    ticket.id === tickets.activeTicketId
  )
  
  if( activeTicket == null ) {
    tickets.activeTicketId = null
  }

  // save back to initialState
  initialState.tickets = tickets
}

if( Object.keys(initialState).length ){ 

  // management
  initialState.auth.appStatus = 0;
  initialState.auth.tokenStatus = -1;
  initialState.auth.reloadAppStatus = 0;

  // generic
  initialState.organizations.listenOrganizationsStart = 0;
  initialState.users.listenUsersStart = 0;
  initialState.branches.listenBranchesStart = 0;
  initialState.misc.listenTownsStart = 0;
  initialState.misc.listenRegionsStart = 0;
  initialState.misc.listenCountriesStart = 0;

  // QM
  initialState.services.listenServicesStart = 0;
  initialState.services.listenServiceSubscriptionsStart = 0;
  initialState.services.listenSupportDesksStart = 0;
  initialState.services.listenPriorityCustomersStart = 0;
  initialState.services.listenPriorityCustomerSubscriptionsStart = 0;

  // tickets
  initialState.tickets.listenTicketsStart = 0;
  initialState.tickets.listenCallTicketsStart = 0;
  initialState.tickets.listenTransferTicketsStart = 0;
  initialState.tickets.listenTicketsCount = 0;
  initialState.tickets.listenTransferTicketsCount = 0;
  initialState.tickets.latestTicket = null;
  
  // customers
  initialState.customers.listenCustomersStart = 0;

  // sessions
  initialState.sessions.sessionsFetchedStatus = 0;
  initialState.sessions.listenSessionsStart = 0;
  initialState.sessions.sessionSavedToDB = 0;
  initialState.sessions.sessionStatus = -1;

  // form builder
  initialState.fb.listenOptionsStart = 0;
  initialState.fb.listenOptionTypesStart = 0;

}

let composeEnhancer = "";
if (Number(process.env.REACT_APP_REDUX_DEVTOOLS_EXTENSION )){
  composeEnhancer = compose(
    applyMiddleware(...middleware),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
}

else {
  composeEnhancer = compose(
    applyMiddleware(...middleware)
  )
}

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancer
);

export default store;
