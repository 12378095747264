import axios from 'axios'
import io from "socket.io-client";
import { 
  LISTEN_CUSTOMERS_START,
  LISTEN_CUSTOMERS_CREATE,
  LISTEN_CUSTOMERS_UPDATE,
  LISTEN_CUSTOMERS_DELETE,
  FETCH_CUSTOMERS
} from './types';

import { getAPI } from '../helpers';

const api = getAPI();
const { REACT_APP_IS_DEV  } = process.env;

let socket = {}; // Declare a variable to hold the Socket.IO client instance
const listenToEvent = (hostname, eventName, updateType) => (dispatch) => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  socket[eventName] = io(baseURL);
  const startType = updateType + '_START';
  const payload = 1;
  dispatch({ type: startType, payload: 1 });
  socket[eventName].on(`${eventName}-updated`, (res) => {
    console.log(startType, "event", res);
    let type, payload;
    if (res.new_val != null) {
      type = updateType + (res.old_val != null ? '_UPDATE' : '_CREATE');
      payload = res.new_val;
    } else {
      type = updateType + '_DELETE';
      payload = res.old_val;
    }
    dispatch({ type, payload })
  });
};

const listenToEventStop = (eventName, updateType) => (dispatch) => {
  if (socket[eventName]) {
    socket[eventName].off(`${eventName}-updated`);
    socket[eventName].disconnect();
    socket[eventName] = null;
  }
  const type = updateType + '_START';
  dispatch({ type, payload: 0 });
}

// Events
export const listenCustomers = (hostname) =>
  listenToEvent(hostname, 'management_customers', 'LISTEN_CUSTOMERS');
export const listenCustomersStop = () =>
listenToEventStop('management_customers', 'LISTEN_CUSTOMERS');

export const fetchCustomers = (hostname) => dispatch => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  axios.get( baseURL + '/management/customers/' )
  .then(res =>
    dispatch({
      type: FETCH_CUSTOMERS,
      payload: res.data
    })
  );
};

export const updateListenCustomersStart = (payload) => dispatch => {
  dispatch({
    type: LISTEN_CUSTOMERS_START,
    payload
  })
};