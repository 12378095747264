import {
  LISTEN_SESSIONS_START,
  LISTEN_SESSIONS_CREATE,
  LISTEN_SESSIONS_UPDATE,
  LISTEN_SESSIONS_DELETE,
  FETCH_SESSIONS,
  CREATE_SESSION,
  UPDATE_SESSION_STATUS,
  UPDATE_SESSION_SAVE_STATUS,
  UPDATE_FETCHED_SESSIONS_STATUS,
} from '../actions/types';

const initialState = {
  listenSessionsStart: 0, // status for whether the listener for sessions data has started
  
  sessions: [],
  sessionsFetchedStatus: 0, // status for whether the sessions have been fetched from the db
  sessionSavedToDB: 0, // status for whether the session data created in app has been saved to the db
  sessionStatus: -1, // whether the session data in the app is valid for the authenticatedUser. -1=undetermined, 0=false, 1=true
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case LISTEN_SESSIONS_START:
      return {
        ...state,
        listenSessionsStart: action.payload
      };
    case LISTEN_SESSIONS_CREATE:
      return {
        ...state,
        sessions: ([ ...state.sessions, action.payload ]).filter((data, index, array) => array.findIndex( data2 => ( data2.id === data.id ) ) === index )
      };
    case LISTEN_SESSIONS_UPDATE:
      return {
        ...state,
        sessions: state.sessions.map(data => {
          if (data.id === action.payload.id) { return action.payload }
          return data;
        })
      };
    case LISTEN_SESSIONS_DELETE:
      return {
        ...state,
        sessions: state.sessions.filter(data => data.id !== action.payload.id )
      };
    case FETCH_SESSIONS:
      return {
        ...state,
        sessions: action.payload,
        sessionsFetchedStatus: 1,
      };
    case UPDATE_FETCHED_SESSIONS_STATUS:
      return {
        ...state,
        sessionsFetchedStatus: action.payload,
      };
    case CREATE_SESSION:
      return {
        ...state,
        sessionSavedToDB: action.payload
      };
    case UPDATE_SESSION_SAVE_STATUS:
      return {
        ...state,
        sessionSavedToDB: action.payload
      };
    case UPDATE_SESSION_STATUS:
      return {
        ...state,
        sessionStatus: action.payload,
      };
    default:
      return state;
  }
}