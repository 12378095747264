import axios from 'axios'
import io from "socket.io-client";
import store from '../index';

import { 
  FETCH_ORGANIZATIONS,
} from './types';

import { getAPI, toastActions } from '../helpers';

const api = getAPI();
const { REACT_APP_IS_DEV  } = process.env;

let socket = {}; // Declare a variable to hold the Socket.IO client instance
const listenToEvent = (hostname, eventName, updateType, organizationCheck = 0) => (dispatch) => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  socket[eventName] = io(baseURL);
  const startType = updateType + '_START';
  const payload = 1;
  dispatch({ type: startType, payload: 1 });
  socket[eventName].on(`${eventName}-updated`, (res) => {
    console.log(startType, "event", res);
    let type, payload;
    if (res.new_val != null) {
      type = updateType + (res.old_val != null ? '_UPDATE' : '_CREATE');
      payload = res.new_val;
    } else {
      type = updateType + '_DELETE';
      payload = res.old_val;
    }

    // dispatch only if the payload is for the active organization
    let organizationId;
    if(organizationCheck){
      const { authenticatedUser } = store.getState().auth;
      organizationId  = authenticatedUser.activeOrganization && authenticatedUser.activeOrganization.length
        ? authenticatedUser.activeOrganization
        : authenticatedUser.organizationId
    }
    
    if(!organizationCheck || payload.id === organizationId){
      dispatch({
        type,
        payload
      })
    }
  });
};

const listenToEventStop = (eventName, updateType) => (dispatch) => {
  if (socket[eventName]) {
    socket[eventName].off(`${eventName}-updated`);
    socket[eventName].disconnect();
    socket[eventName] = null;
  }
  const type = updateType + '_START';
  dispatch({ type, payload: 0 });
}

// Events
export const listenOrganizations = (hostname) =>
  listenToEvent(hostname, 'admin_organizations', 'LISTEN_ORGANIZATIONS', 0);
export const listenOrganizationsStop = () =>
listenToEventStop('admin_organizations', 'LISTEN_ORGANIZATIONS');

export const fetchOrganizations = (hostname, payload) => async (dispatch) => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  try {
    const res = await axios.get( baseURL + "/admin/organizations", payload);
    dispatch({
      type: FETCH_ORGANIZATIONS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};