import React from 'react';
import { connect } from 'react-redux'

class CustomerDetails extends React.Component {
    townships = [
        { id: '3bc75db1-931a-42b1-a6cc-1563a9f394db', name: 'Windhoek' },
        { id: '6dd6b31f-d289-4a50-a14e-5a443824907d', name: 'Sungate' },
        { id: '47dc9bed-89e2-4689-949d-878bd6344c8d', name: 'Herbothblick' },
        { id: 'd17cee17-b707-4b6d-9e7e-334ba4c4bbb9', name: 'Farm Ondekaremba No 78' },
        { id: '427048e4-e2b9-4e68-ad24-b80fed8771f2', name: 'Farm Manyhills No 580' },
        { id: 'a45df01f-0bc6-4912-9f2a-dfa612d792d1', name: 'Farm Regenstein 32' },
        { id: '8f42ae76-1a36-4e50-9a8f-cead8272af69', name: 'Eros Park' },
        { id: '2f9acdab-3abc-4e3b-8464-b3d91dae6462', name: 'Klein Whk' },
        { id: '706dea59-6a94-47ec-8f4d-cbfdefe3858f', name: 'Farm Eros No 69' },
        { id: '2fa08d2c-a5d6-4f61-88c1-18f1ccb4a159', name: 'Farm Klein Whk Town & Townland No 70' },
        { id: '8721d34e-6ec4-4a12-b52b-6e406e61c96f', name: 'Farm Whk Town & Townlands No 31' },
        { id: '0d2a3a5b-0498-4674-8b19-7b5f975102e1', name: 'Farm Finckenstein; Finkenstein' },
        { id: '1f4ea233-a9e0-4b42-9f70-4b4cf9a8e4fa', name: 'Omeya' },
        { id: '96638a8e-4a07-4109-928f-63c3e9899baf', name: 'Olympia' },
        { id: '832a395d-cfa1-4d89-b8d6-097fa90142ce', name: 'AuasBlick' },
        { id: '5042cab1-7673-4df4-a78b-7a4bfbf89c6c', name: 'Kleine Kuppe' },
        { id: 'db7060b5-c7c4-41b4-80aa-c8308aff8881', name: 'Pionierspark' },
        { id: '25faa83d-b479-4ff1-8641-08626467837b', name: 'Cimbebasia' },
        { id: '0e4ac2fd-4031-497d-90fc-da7cd18661af', name: 'Prosperita' },
        { id: '3007099f-78c4-4d44-85d9-c25c8c601da4', name: 'Academia' },
        { id: 'b283ca78-5537-4f47-bdc0-846f3a6cb2f3', name: 'Hochlandpark' },
        { id: 'e78a401d-9e20-4239-ab65-7bc42bfb1e64', name: 'Rocky Crest' },
        { id: 'ae5d18ad-470c-4cee-94bf-74fc78d0f826', name: 'Katutura' },
        { id: '469293ba-304b-4b4b-824f-36fb9694301b', name: 'Khomasdal' },
        { id: 'bf45e53b-f42a-48bb-9d25-474f49b00f01', name: 'Dorado Park' },
        { id: '96589887-e8ac-4bdd-81cd-2b1c2596cad0', name: 'Otjomuise' },
        { id: '0d073714-f2ef-4587-94e3-29146c549f30', name: 'Goreangab' },
        { id: 'e4a1adcf-6535-4f8f-91c0-04d3c4c85e9e', name: 'Hakahana' },
        { id: 'c7625e9a-aa30-433c-8aa5-9b67cbea0748', name: 'Hillside' },
        { id: '2023885c-c9ad-430e-a84b-98bddb9ef333', name: 'Brakwater Industrial Estate' },
        { id: 'b7cbbfb0-df12-4ab1-a48b-88cd8a7c3873', name: 'Farm 428' },
        { id: '1d75385d-3fd7-483b-b0be-9e7d7005a456', name: 'Elisenheim' },
        { id: 'fa550e69-9d84-4ba1-b9ae-e5f0cdf8c820', name: 'Wanaheda' },
        { id: '7bd08e38-e96c-4aeb-af98-55dcfb7cf7f4', name: 'Havana' },
        { id: '907253ca-bb6c-498c-a6f5-811dcd370140', name: 'Okuryangava' },
        { id: '79c8ff93-e630-411c-9e9b-a0770953e70d', name: 'Lafrenz' },
        { id: 'e229f2bc-7870-4b73-a1ad-964620385e3e', name: 'Farm Brakwater No 48' },
        { id: 'dcd87f40-fbe0-4837-b4b4-91a6ade5613c', name: 'Farm Emmarentia No 380' },
        { id: 'c8fa79d6-f8b2-451b-ae81-1f4901c1cbf3', name: 'Farm Dobra No 49' },
        { id: '0e7c3f3e-c6f1-46f4-9327-b755f5273426', name: 'Farm Nubuamis No 37' },
        { id: 'e94581bf-84a6-4b37-b9ff-2e4eaf552d29', name: 'Thorn Valley' },
      ];

    render(){
        
        const { ticket, priorityCustomers } = this.props
        const { customers } = this.props

        // if( !customers.length ){  return <p>Loading customers</p> }
        if(ticket == null ) { return <p>No tickets available</p> }

        const customer = customers.find( a => a.mobileNumber === ticket.mobileNumber)
        const priorityCustomer = priorityCustomers.find( a => a.id === ticket.priorityCustomer)

        return (<div>
            <div className="informationContainer">
                <div className="informationRow">
                    <label>Priority Customer</label>
                    {
                        ticket != null
                            ?   ticket.priority > 0
                                ?   <span>{`Yes${ priorityCustomer != null ? ', ' + priorityCustomer.name : ''}`}</span>
                                :   <span>No Customer details</span>
                            :   <span>Unavailable</span>
                    }
                </div>
                    <div className="informationRow">
                        <label>Full Name</label>
                            {
                                customer != null
                                    ?   !customer.fullname.length
                                        ?   <span>No Customer details</span>
                                        :   <span>{customer.fullname}</span>
                                    :   <span>Unavailable</span>
                            }
                    </div>
                    <div className="informationRow">
                        <label>Mobile Number</label>
                            {
                                customer != null
                                    ?   !customer.mobileNumber.length
                                        ?   <span>Unavailable</span>
                                        :   <span>{customer.mobileNumber}</span>
                                    :   <span>Unavailable</span>
                            }
                    </div>
                    {
                        customer != null
                            ?   customer.email != null
                                ?   customer.email.toString().length === 0
                                        ?   null
                                        :   (
                                                <div className="informationRow">
                                                    <label>Email</label>
                                                    <span>{ customer.email }</span>
                                                </div>
                                            )
                                :   null
                            :   null
                    }
                    {
                        customer != null
                            ?   customer.erfNumber != null
                                ?   customer.erfNumber.toString().length === 0
                                        ?   null
                                        :   (
                                                <div className="informationRow">
                                                    <label>ERF Number</label>
                                                    <span>{ customer.erfNumber }</span>
                                                </div>
                                            )
                                :   null
                            :   null
                    }
                    {
                        customer != null
                            ?   customer.township != null
                                ?   customer.township.toString().length === 0
                                        ?   null
                                        :   (
                                                <div className="informationRow">
                                                    <label>Township</label>
                                                    <span>{ this.townships.find(a=>a.id === customer.township)?.name || 'Unavailable' }</span>
                                                </div>
                                            )
                                :   null
                            :   null
                    }
                </div>
        </div>
        )
    }

}

const mapStateToProps = state => {
    const { tickets, customers, services } = state
    return {
        tickets: tickets.tickets,
        activeTicketId: tickets.activeTicketId,

        customers: customers.customers,
        priorityCustomers: services.priorityCustomers,
    }
}

    export default connect(mapStateToProps, {})(CustomerDetails);