import React from "react";
import { connect } from 'react-redux'
import addNotification from "react-push-notification";
import { updateActiveTicket } from '../../../store/actions/ticketsActions'

class NewTicketHelper extends React.Component {
    componentDidUpdate(prevProps, prevState, snapshot){
        let { authenticatedUser, tickets, services, listenTicketsCount } = this.props

        if ( prevProps.tickets.length < tickets.length && listenTicketsCount !== 0 ) {
            console.log("go1");
            const latestTicket = tickets[ tickets.length - 1 ]
            if( authenticatedUser.services.includes(latestTicket.serviceId) ){
                console.log("go2");
                addNotification({
                    title: 'New Ticket '+ latestTicket.ticketLetter +'-'+ latestTicket.ticketNumber,
                    message: 'Ticket service:  ' + services.find( data => data.id === latestTicket.serviceId ).name,
                    onClick: 'https://agent.averly.com.na',
                    native: true 
                });
            }
        }
    }

    render() {
        return null
    }
}

const mapStateToProps = state => {
    const { auth, tickets, sessions, services } = state
    return {
        tickets: tickets.tickets,
        listenTicketsCount: tickets.listenTicketsCount,
        callTickets: tickets.callTickets,
        activeTicketId: tickets.activeTicketId,
        authenticatedUser: auth.authenticatedUser,
        sessions: sessions.sessions,
        services: services.services,
    }
}

export default connect(mapStateToProps, { 
  updateActiveTicket,
})(NewTicketHelper);
