import React from "react";
import { connect } from 'react-redux'

import { updateActiveTicket } from '../../../store/actions/ticketsActions'

class ActiveTicketHelper extends React.Component {
    componentDidUpdate(){
        const { tickets, authenticatedUser, activeTicketId } = this.props
        const { updateActiveTicket } = this.props

        const priorityTickets = tickets
        .filter( data => Number(data.status) === 1 )
        .filter( data => 
            data.manage != null
            ? data.manage.userId != null
            ? data.manage.userId === authenticatedUser.id
            : 0
            : 0
        )

        if( priorityTickets.length){
            const { id } = priorityTickets[0]
            if( activeTicketId == null ) updateActiveTicket({ id })
        } else {
            if( activeTicketId != null ) updateActiveTicket({ id: null })
        }

    }

    render() {
        return null
    }
}

const mapStateToProps = state => {
    const { auth, tickets } = state
    return {
        tickets: tickets.tickets,
        callTickets: tickets.callTickets,
        activeTicketId: tickets.activeTicketId,
        authenticatedUser: auth.authenticatedUser,
    }
}

export default connect(mapStateToProps, { 
  updateActiveTicket,
})(ActiveTicketHelper);
