import axios from 'axios';
import { toast } from "react-toastify";

import { 

  FETCH_CLOUD_ORGANIZATIONS,
  FETCH_CLOUD_BRANCHES,
  UPDATE_SUPPORT_DESK_ID,
  UPDATE_SETUP_STATUS,
  UPDATE_APP_STATUS,
  UPDATE_TOKEN_STATUS,
  AUTHENTICATE_USER,
  UNAUTHENTICATE_USER,
  AUTHENTICATION_ERROR,
  UPDATE_RELOAD_APP_STATUS,
  FETCH_SETUP_INFO,
  UPDATE_ORGANIZATION_ID,
  UPDATE_HOST_NAME,
  UPDATE_BRANCH_ID
} from '../actions/types';

import { getAPI } from '../helpers';
const api = getAPI();
const cloudApi = 'https://cx.api.averly.com.na'
const { REACT_APP_IS_DEV  } = process.env;

export const authenticateUser = (hostname, authenticationData) => async (dispatch) => {
  if (!authenticationData.supportDeskId.length || !authenticationData.email.length || !authenticationData.password.length) {
    toast.error("Counter/Station, Email & Password are required");
    return;
  }
  try {
    const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
    const res = await axios.post(baseURL + '/auth/authenticate', authenticationData);
    const payload = res.data;
    const type = payload.status ? AUTHENTICATE_USER : AUTHENTICATION_ERROR;
    dispatch({
      type,
      payload
    });
  } catch (err) {
    toast.error("Invalid Email or Password");
  }
};

export const tokenStatusCheck = (hostname, accessToken) => async (dispatch) => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  try {
    await axios.get(baseURL + '/auth/session-check/', {
      headers: {
        'Authorization': `Basic ${accessToken}`
      }
    });

    dispatch({
      type: UPDATE_TOKEN_STATUS,
      payload: 1
    });
  } catch (err) {
    toast.warning("Your session has expired");
    
    dispatch({
      type: UPDATE_TOKEN_STATUS,
      payload: 0
    });
  }
};

// fetch organizationId and BranchId
export const fetchSetupInfo = (hostname) => async (dispatch) => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;

  dispatch( updateSetupStatus(-1) );

  const endpoint = '/admin/organizations/setup';
  const type = FETCH_SETUP_INFO;
  try {
    let res = await axios.get(baseURL + endpoint, { timeout: 15000 }); 
    const status = res.data.organizationId.length && res.data.branchId.length ? 1 : 0;
    const payload = { ...res.data, status }
    dispatch({ type, payload });
  } 
  catch (error) {
    const payload = { status: 0 }
    dispatch({ type, payload });
    dispatch({ type: UPDATE_SETUP_STATUS, payload: 0 });
  }
};

// fetch cloud organizations
export const fetchCloudOrganizations = () => async (dispatch) => {
  const endpoint = '/admin/organizations/';
  const type = FETCH_CLOUD_ORGANIZATIONS;
  try {
    let res = await axios.get(cloudApi + endpoint);
    const payload = res.data
    dispatch({ type, payload });
  } 
  catch (error) {
    const payload = { status: 0 }
    dispatch({ type, payload });
  }
};

// fetch cloud branches for organizationId
export const fetchCloudBranches = (organizationId) => async (dispatch) => {
  const endpoint = '/admin/branches/';
  const type = FETCH_CLOUD_BRANCHES;
  try {
    let res = await axios.get(cloudApi + endpoint, { params: { organizationId } } );
    const payload = res.data
    dispatch({ type, payload });
  }
  catch (error) {
    const payload = { status: 0 }
    dispatch({ type, payload });
  }
};
export const resetHostname = () => dispatch => {
  dispatch({ type: UPDATE_HOST_NAME, payload: '' });
  // reset organizationId & branchId & supportDeskId
  dispatch({ type: UPDATE_ORGANIZATION_ID, payload: '' });
  dispatch({ type: UPDATE_BRANCH_ID, payload: '' });
  dispatch({ type: UPDATE_SUPPORT_DESK_ID, payload: '' });
  // empty cloud organizations and branches
  dispatch({ type: FETCH_CLOUD_ORGANIZATIONS, payload: [] });
  dispatch({ type: FETCH_CLOUD_BRANCHES, payload: [] });
  // set setup status to -1
  dispatch({ type: UPDATE_SETUP_STATUS, payload: -1 });
  // set token status to 0
  dispatch({ type: UPDATE_TOKEN_STATUS, payload: -1 });
  // set app status to 0
  dispatch({ type: UPDATE_APP_STATUS, payload: 0 });
}
// update branchId & hostname
export const updateHostname = (hostname) => (dispatch) => {
  dispatch({ type: UPDATE_HOST_NAME, payload: hostname });
};

export const updateSupportDeskId = (payload) => dispatch => {
    if( payload.length ){
      dispatch({
        type: UPDATE_SUPPORT_DESK_ID,
        payload
      })
    } else {
      toast.error("Please select your Counter / Station");
    }
};
export const unAuthenticateUser = () => dispatch => {
  dispatch({
    type: UNAUTHENTICATE_USER,
    payload: {}
  })
}
export const updateTokenStatus = (payload) => dispatch => {
    dispatch({
      type: UPDATE_TOKEN_STATUS,
      payload
    })
}
export const updateSetupStatus = (payload) => dispatch => {
    dispatch({
      type: UPDATE_SETUP_STATUS,
      payload
    })
};
export const updateAppStatus = (payload) => dispatch => {
    dispatch({
      type: UPDATE_APP_STATUS,
      payload
    })
};