import React from 'react';
import { connect } from 'react-redux'

import {
    modalUpdate,
} from "../../../../store/actions/settingsActions";

import {
    updateActiveManagementTicketId,
} from "../../../../store/actions/ticketsActions";

class MoreTicket extends React.Component {
    handleModalClick(id = null){
        const { modalUpdate, updateActiveManagementTicketId } = this.props
        id = id != null
            ?   id
            :   this.props.ticket.id
            
        updateActiveManagementTicketId(id)
        modalUpdate({ visible: 1, content: "TicketManagement", modalType: 1 })
    }

    render(){
        let { authenticatedUser, activeTicketId, tickets, sessions } = this.props
        //  first we handle tickets that were already called by my userId. This could be caused by
        // an agent that logged out or browser closed before the ticket was closed
        let priorityTickets = tickets.filter( data => 
            ( Number(data.status) === 1 && data.manage.userId === authenticatedUser.id)
        )
        


        // we check for priorityTickets
        // we will add this later


        // if there are no open tickets
        if( !priorityTickets.length ){
            // find tickets that are not served yet
            priorityTickets = tickets.filter( data => 
                (   
                    // get tickets that are not called yet AND
                    ( Number(data.status) === 0 
                        && (
                            //  are included in my services
                            //  first make sure it is not allocated to something else
                            (
                                data.manage != null
                                ?   data.manage.userId == null && data.manage.supportDeskId == null
                                    ?   ( authenticatedUser.services.includes(data.serviceId) )
                                    : 1 === 2 // used to exit check
                                : ( authenticatedUser.services.includes(data.serviceId) )
                            )

                            // or were sent to my userId
                            || ( 
                                data.manage != null 
                                ?   data.manage.userId != null
                                    ?   data.manage.userId === authenticatedUser.id
                                    :   1 === 2 // used to exit check
                                :   1 === 2 // used to exit check
                            )

                            // or were sent to my supportDesk
                            || ( 
                                // get the supportDeskId my userId is logged in with and compare with ticket supportDeskId
                                data.manage != null 
                                ?   data.manage.supportDeskId != null
                                    ?   ( 
                                            data.manage.supportDeskId === sessions
                                                .find( session => 
                                                    session.userId === authenticatedUser.id 
                                                ).supportDeskId 
                                        )
                                    :   1 === 2 // used to exit check
                                :   1 === 2 // used to exit check
                            )
                        )
                    )
                       
                )
            )

        }
        const activeTicket = priorityTickets.find( data => data.id === activeTicketId )
        const ticket = activeTicketId == null
        ?   priorityTickets[0]
        :   activeTicket != null
            ?   activeTicket
            :   priorityTickets[0]

        const content = <>
            <svg xmlns="http://www.w3.org/2000/svg" width="65" height="66" fill="none" viewBox="0 0 65 66">
                <circle cx="30.5" cy="17.5" r="14.5" stroke="#626BE7" strokeWidth="4.287"/>
                <path stroke="#626BE7" strokeWidth="4.287" d="M3 60c0-15.464 12.088-28 27-28"/>
                <path fill="#626BE7" d="M50.143 46.955v-11.45c0-.542-.211-1.063-.589-1.447-.377-.384-.89-.6-1.423-.6-.534-.001-1.047.214-1.425.597-.378.384-.59.904-.591 1.447v11.45H34.851c-.535 0-1.048.215-1.426.6-.378.383-.59.905-.59 1.448 0 .543.212 1.065.59 1.449.378.384.891.6 1.426.6h11.264v11.449c0 .543.212 1.064.59 1.448.378.385.891.6 1.426.6.534 0 1.047-.215 1.425-.6.378-.384.59-.905.59-1.448v-11.45h11.265c.534 0 1.047-.215 1.425-.6.378-.383.59-.905.59-1.448 0-.543-.212-1.065-.59-1.449-.378-.384-.89-.6-1.425-.6l-11.268.004z"/>
            </svg>
            <h4>Manage</h4>
        </>

        return <div className="home-left-content2-item item5 call-more" onClick={ () => this.handleModalClick(ticket.id) }>{ content }</div>
    }
}

const mapStateToProps = state => {
    const { auth, tickets, sessions } = state
    return {
       tickets: tickets.tickets,
        sessions: sessions.sessions,
        activeTicketId: tickets.activeTicketId,
        authenticatedUser: auth.authenticatedUser
    }
}

export default connect(mapStateToProps, {
    modalUpdate,
    updateActiveManagementTicketId,
})(MoreTicket)