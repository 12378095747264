import { 
  LISTEN_TICKETS_START,
  LISTEN_TICKETS_CREATE,
  LISTEN_TICKETS_UPDATE,
  LISTEN_TICKETS_DELETE,
  FETCH_TICKETS,
  UPDATE_ACTIVE_TICKET,
  UPDATE_ACTIVE_MANAGEMENT_TICKET,
  LISTEN_CALL_TICKETS_START,
  LISTEN_CALL_TICKETS_CREATE,
  LISTEN_TRANSFER_TICKETS_START,
  LISTEN_TRANSFER_TICKETS_CREATE,
  FETCH_CALL_TICKETS,
  FETCH_TRANSFER_TICKETS,
  SAVE_LATEST_TICKET
} from '../actions/types';

const initialState = {
  activeTicketId: null,
  activeManagementTicketId: null,
  listenTicketsStart: 0,
  tickets: [],
  listenCallTicketsStart: 0,
  callTickets: [],
  listenTransferTicketsStart: 0,
  transferTickets: [],
  listenTicketsCount: 0,
  listenTransferTicketsCount: 0,
  latestTicket: null
}

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case LISTEN_TICKETS_START:
      return {
        ...state,
        listenTicketsStart: action.payload
      };
    case LISTEN_TICKETS_CREATE:
      return {
        ...state,
        tickets: ([ ...state.tickets, action.payload ]).filter((data, index, array) => array.findIndex( data2 => ( data2.id === data.id ) ) === index ).sort((a, b) => a.created - b.created),
        listenTicketsCount: state.listenTicketsCount + 1,
        latestTicket: action.payload
      };
    case SAVE_LATEST_TICKET:
      return {
        ...state,
        latestTicket: action.payload
      };
    case LISTEN_TICKETS_UPDATE:
      return {
        ...state,
        tickets: (state.tickets.map(data => (data.id === action.payload.id)? action.payload :  data )).sort((a, b) => a.created - b.created)
      };
    case LISTEN_TICKETS_DELETE:
      return {
        ...state,
        tickets: (state.tickets.filter(data => data.id !== action.payload.id )).sort((a, b) => a.created - b.created)
      };
    case FETCH_TICKETS:
      return {
        ...state,
        tickets: action.payload.sort((a, b) => a.created - b.created)
      };
    case UPDATE_ACTIVE_TICKET:
      return {
        ...state,
        activeTicketId: action.payload.id,
      };
    case LISTEN_CALL_TICKETS_START:
      return {
        ...state,
        listenCallTicketsStart: action.payload
      };
    case LISTEN_CALL_TICKETS_CREATE:
      return {
        ...state,
        callTickets: ([ ...state.callTickets, action.payload ]).filter((data, index, array) => array.findIndex( data2 => ( data2.id === data.id ) ) === index ).sort((a, b) => b.created - a.created)
      };
    case FETCH_CALL_TICKETS:
      return {
        ...state,
        callTickets: action.payload.sort((a, b) => b.created - a.created)
      };
    case LISTEN_TRANSFER_TICKETS_START:
      return {
        ...state,
        listenTransferTicketsStart: action.payload
      };
    case LISTEN_TRANSFER_TICKETS_CREATE:
      return {
        ...state,
        transferTickets: ([ ...state.transferTickets, action.payload ]).filter((data, index, array) => array.findIndex( data2 => ( data2.id === data.id ) ) === index ),
        listenTransferTicketsCount: state.listenTransferTicketsCount + 1
      };
    case FETCH_TRANSFER_TICKETS:
      return {
        ...state,
        transferTickets: action.payload
      };
    case UPDATE_ACTIVE_MANAGEMENT_TICKET:
      return {
        ...state,
        activeManagementTicketId: action.payload
      };

    default:
      return state;
  }
}