
// Auth
export const FETCH_CLOUD_ORGANIZATIONS = 'FETCH_CLOUD_ORGANIZATIONS';
export const FETCH_CLOUD_BRANCHES = 'FETCH_CLOUD_BRANCHES';
export const UPDATE_ORGANIZATION_ID = 'UPDATE_ORGANIZATION_ID';
export const UPDATE_BRANCH_ID = 'UPDATE_BRANCH_ID';
export const UPDATE_HOST_NAME = 'UPDATE_HOST_NAME';
export const UPDATE_SETUP_STATUS = 'UPDATE_SETUP_STATUS';
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const UPDATE_APP_STATUS = 'UPDATE_APP_STATUS';
export const UPDATE_TOKEN_STATUS = 'UPDATE_TOKEN_STATUS';
export const UNAUTHENTICATE_USER = 'UNAUTHENTICATE_USER';
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
export const UPDATE_ACTIVE_ORGANIZATION = 'UPDATE_ACTIVE_ORGANIZATION';
export const UPDATE_RELOAD_APP_STATUS = 'UPDATE_RELOAD_APP_STATUS';


// users
export const LISTEN_USERS_START = 'LISTEN_USERS_START';
export const LISTEN_USERS_UPDATE = 'LISTEN_USERS_UPDATE';
export const LISTEN_USERS_CREATE = 'LISTEN_USERS_CREATE';
export const LISTEN_USERS_DELETE = 'LISTEN_USERS_DELETE';
export const FETCH_USERS = 'FETCH_USERS';

export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';


// organizations
export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
export const FETCH_ORGANIZATIONS = 'FETCH_ORGANIZATIONS';
export const LISTEN_ORGANIZATIONS_START = 'LISTEN_ORGANIZATIONS_START';
export const LISTEN_ORGANIZATIONS_UPDATE = 'LISTEN_ORGANIZATIONS_UPDATE';
export const LISTEN_ORGANIZATIONS_CREATE = 'LISTEN_ORGANIZATIONS_CREATE';
export const LISTEN_ORGANIZATIONS_DELETE = 'LISTEN_ORGANIZATIONS_DELETE';
export const UPDATE_ORGANIZATION_KEY_VALUE = 'UPDATE_ORGANIZATION_KEY_VALUE';
export const UPDATE_ORGANIZATION_GET_OBJECT = 'UPDATE_ORGANIZATION_GET_OBJECT';
export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
export const RESET_ORGANIZATION = 'RESET_ORGANIZATION';

// branches
export const LISTEN_BRANCHES = 'LISTEN_BRANCHES';
export const UPDATE_BRANCH = 'UPDATE_BRANCH';
export const RESET_BRANCH = 'RESET_BRANCH';
export const UPDATE_BRANCH_KEY_VALUE = 'UPDATE_BRANCH_KEY_VALUE';
export const UPDATE_BRANCH_GET_OBJECT = 'UPDATE_BRANCH_GET_OBJECT';
export const LISTEN_BRANCHES_START = 'LISTEN_BRANCHES_START';
export const LISTEN_BRANCHES_UPDATE = 'LISTEN_BRANCHES_UPDATE';
export const LISTEN_BRANCHES_CREATE = 'LISTEN_BRANCHES_CREATE';
export const LISTEN_BRANCHES_DELETE = 'LISTEN_BRANCHES_DELETE';
export const FETCH_BRANCHES = 'FETCH_BRANCHES';

// services
export const LISTEN_SERVICES_START = 'LISTEN_SERVICES_START';
export const LISTEN_SERVICES_UPDATE = 'LISTEN_SERVICES_UPDATE';
export const LISTEN_SERVICES_CREATE = 'LISTEN_SERVICES_CREATE';
export const LISTEN_SERVICES_DELETE = 'LISTEN_SERVICES_DELETE';
export const FETCH_SERVICES = 'FETCH_SERVICES';

export const LISTEN_SERVICE_SUBSCRIPTIONS_START = 'LISTEN_SERVICE_SUBSCRIPTIONS_START';
export const LISTEN_SERVICE_SUBSCRIPTIONS_UPDATE = 'LISTEN_SERVICE_SUBSCRIPTIONS_UPDATE';
export const LISTEN_SERVICE_SUBSCRIPTIONS_CREATE = 'LISTEN_SERVICE_SUBSCRIPTIONS_CREATE';
export const LISTEN_SERVICE_SUBSCRIPTIONS_DELETE = 'LISTEN_SERVICE_SUBSCRIPTIONS_DELETE';
export const FETCH_SERVICE_SUBSCRIPTIONS = 'FETCH_SERVICE_SUBSCRIPTIONS';

export const LISTEN_SUPPORT_DESKS_START = 'LISTEN_SUPPORT_DESKS_START';
export const LISTEN_SUPPORT_DESKS_UPDATE = 'LISTEN_SUPPORT_DESKS_UPDATE';
export const LISTEN_SUPPORT_DESKS_CREATE = 'LISTEN_SUPPORT_DESKS_CREATE';
export const LISTEN_SUPPORT_DESKS_DELETE = 'LISTEN_SUPPORT_DESKS_DELETE';
export const FETCH_SUPPORT_DESKS = 'FETCH_SUPPORT_DESKS';

export const LISTEN_PRIORITY_CUSTOMERS_START = 'LISTEN_PRIORITY_CUSTOMERS_START';
export const LISTEN_PRIORITY_CUSTOMERS_UPDATE = 'LISTEN_PRIORITY_CUSTOMERS_UPDATE';
export const LISTEN_PRIORITY_CUSTOMERS_CREATE = 'LISTEN_PRIORITY_CUSTOMERS_CREATE';
export const LISTEN_PRIORITY_CUSTOMERS_DELETE = 'LISTEN_PRIORITY_CUSTOMERS_DELETE';
export const FETCH_PRIORITY_CUSTOMERS = 'FETCH_PRIORITY_CUSTOMERS';

export const LISTEN_PRIORITY_CUSTOMER_SUBSCRIPTIONS_START = 'LISTEN_PRIORITY_CUSTOMER_SUBSCRIPTIONS_START';
export const LISTEN_PRIORITY_CUSTOMER_SUBSCRIPTIONS_UPDATE = 'LISTEN_PRIORITY_CUSTOMER_SUBSCRIPTIONS_UPDATE';
export const LISTEN_PRIORITY_CUSTOMER_SUBSCRIPTIONS_CREATE = 'LISTEN_PRIORITY_CUSTOMER_SUBSCRIPTIONS_CREATE';
export const LISTEN_PRIORITY_CUSTOMER_SUBSCRIPTIONS_DELETE = 'LISTEN_PRIORITY_CUSTOMER_SUBSCRIPTIONS_DELETE';
export const FETCH_PRIORITY_CUSTOMER_SUBSCRIPTIONS = 'FETCH_PRIORITY_CUSTOMER_SUBSCRIPTIONS';

export const RESET_SERVICE = 'RESET_SERVICE';
export const UPDATE_SERVICE_KEY_VALUE = 'UPDATE_SERVICE_KEY_VALUE';
export const UPDATE_SERVICE_GET_OBJECT = 'UPDATE_SERVICE_GET_OBJECT';
export const LISTEN_SERVICE_SUBSCRIPTIONS = 'LISTEN_SERVICE_SUBSCRIPTIONS';
export const RESET_SERVICE_SUBSCRIPTION = 'RESET_SERVICE_SUBSCRIPTION';
export const PARTIAL_RESET_SERVICE_SUBSCRIPTION = 'PARTIAL_RESET_SERVICE_SUBSCRIPTION';
export const UPDATE_SERVICE_SUBSCRIPTION_KEY_VALUE = 'UPDATE_SERVICE_SUBSCRIPTION_KEY_VALUE';
export const UPDATE_SERVICE_SUBSCRIPTION_GET_OBJECT = 'UPDATE_SERVICE_SUBSCRIPTION_GET_OBJECT';
export const LISTEN_SUPPORT_DESKS = 'LISTEN_SUPPORT_DESKS';
export const RESET_SUPPORT_DESK = 'RESET_SUPPORT_DESK';
export const UPDATE_SUPPORT_DESK_KEY_VALUE = 'UPDATE_SUPPORT_DESK_KEY_VALUE';
export const UPDATE_SUPPORT_DESK_GET_OBJECT = 'UPDATE_SUPPORT_DESKSGET_OBJECT';

// settings
export const LISTEN_STYLING_DS_START = 'LISTEN_STYLING_DS_START';
export const LISTEN_STYLING_DS_UPDATE = 'LISTEN_STYLING_DS_UPDATE';
export const LISTEN_STYLING_DS_CREATE = 'LISTEN_STYLING_DS_CREATE';
export const LISTEN_STYLING_DS_DELETE = 'LISTEN_STYLING_DS_DELETE';
export const FETCH_STYLING_DS = 'FETCH_STYLING_DS';
export const UPDATE_STYLING_DS_KEY_VALUE = 'UPDATE_STYLING_DS_KEY_VALUE';
export const UPDATE_STYLING_DS_GET_OBJECT = 'UPDATE_STYLING_DS_GET_OBJECT';
export const RESET_STYLING_DS = 'RESET_STYLING_DS';

export const LISTEN_STYLING_QM_START = 'LISTEN_STYLING_QM_START';
export const LISTEN_STYLING_QM_UPDATE = 'LISTEN_STYLING_QM_UPDATE';
export const LISTEN_STYLING_QM_CREATE = 'LISTEN_STYLING_QM_CREATE';
export const LISTEN_STYLING_QM_DELETE = 'LISTEN_STYLING_QM_DELETE';
export const FETCH_STYLING_QM = 'FETCH_STYLING_QM';
export const UPDATE_STYLING_QM_KEY_VALUE = 'UPDATE_STYLING_QM_KEY_VALUE';
export const UPDATE_STYLING_QM_GET_OBJECT = 'UPDATE_STYLING_QM_GET_OBJECT';
export const RESET_STYLING_QM = 'RESET_STYLING_QM';

export const MODAL_UPDATE = 'MODAL_UPDATE';
export const UPDATE_CURRENT_APP = 'UPDATE_CURRENT_APP';
export const UPDATE_VIEWPORT = 'UPDATE_VIEWPORT';
export const UPDATE_SIDEBAR_STATUS = 'UPDATE_VIEUPDATE_SIDEBAR_STATUSWPORT';
export const UPDATE_TOOLBAR = 'UPDATE_TOOLBAR';

//  endpoints
export const LISTEN_ENDPOINTS_START = 'LISTEN_ENDPOINTS_START';
export const LISTEN_ENDPOINTS_UPDATE = 'LISTEN_ENDPOINTS_UPDATE';
export const LISTEN_ENDPOINTS_CREATE = 'LISTEN_ENDPOINTS_CREATE';
export const LISTEN_ENDPOINTS_DELETE = 'LISTEN_ENDPOINTS_DELETE';
export const FETCH_ENDPOINTS = 'FETCH_ENDPOINTS';
export const UPDATE_ENDPOINT = 'UPDATE_ENDPOINT';
export const RESET_ENDPOINT = 'RESET_ENDPOINT';
export const UPDATE_ENDPOINT_KEY_VALUE = 'UPDATE_ENDPOINT_KEY_VALUE';
export const UPDATE_ENDPOINT_GET_OBJECT = 'UPDATE_ENDPOINT_GET_OBJECT';

// misc
export const LISTEN_TOWNS_START = 'LISTEN_TOWNS_START';
export const LISTEN_TOWNS_UPDATE = 'LISTEN_TOWNS_UPDATE';
export const LISTEN_TOWNS_CREATE = 'LISTEN_TOWNS_CREATE';
export const LISTEN_TOWNS_DELETE = 'LISTEN_TOWNS_DELETE';
export const FETCH_TOWNS = 'FETCH_TOWNS';
export const RESET_TOWN = 'RESET_TOWN';
export const UPDATE_TOWN_KEY_VALUE = 'UPDATE_TOWN_KEY_VALUE';
export const UPDATE_TOWN_GET_OBJECT = 'UPDATE_TOWN_GET_OBJECT';

export const LISTEN_AUDIENCES_START = 'LISTEN_AUDIENCES_START';
export const LISTEN_AUDIENCES_UPDATE = 'LISTEN_AUDIENCES_UPDATE';
export const LISTEN_AUDIENCES_CREATE = 'LISTEN_AUDIENCES_CREATE';
export const LISTEN_AUDIENCES_DELETE = 'LISTEN_AUDIENCES_DELETE';
export const FETCH_AUDIENCES = 'FETCH_AUDIENCES';

export const LISTEN_PRODUCTS_START = 'LISTEN_PRODUCTS_START';
export const LISTEN_PRODUCTS_UPDATE = 'LISTEN_PRODUCTS_UPDATE';
export const LISTEN_PRODUCTS_CREATE = 'LISTEN_PRODUCTS_CREATE';
export const LISTEN_PRODUCTS_DELETE = 'LISTEN_PRODUCTS_DELETE';
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';

export const LISTEN_REGIONS_START = 'LISTEN_REGIONS_START';
export const LISTEN_REGIONS_UPDATE = 'LISTEN_REGIONS_UPDATE';
export const LISTEN_REGIONS_CREATE = 'LISTEN_REGIONS_CREATE';
export const LISTEN_REGIONS_DELETE = 'LISTEN_REGIONS_DELETE';
export const FETCH_REGIONS = 'FETCH_REGIONS';

export const LISTEN_COUNTRIES_START = 'LISTEN_COUNTRIES_START';
export const LISTEN_COUNTRIES_UPDATE = 'LISTEN_COUNTRIES_UPDATE';
export const LISTEN_COUNTRIES_CREATE = 'LISTEN_COUNTRIES_CREATE';
export const LISTEN_COUNTRIES_DELETE = 'LISTEN_COUNTRIES_DELETE';
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';

//  storage
export const LISTEN_STORAGE_START = 'LISTEN_STORAGE_START';
export const LISTEN_STORAGE_UPDATE = 'LISTEN_STORAGE_UPDATE';
export const LISTEN_STORAGE_CREATE = 'LISTEN_STORAGE_CREATE';
export const LISTEN_STORAGE_DELETE = 'LISTEN_STORAGE_DELETE';
export const FETCH_STORAGE = 'FETCH_STORAGE';
export const UPDATE_STORAGE = 'UPDATE_STORAGE';
export const RESET_STORAGE = 'RESET_STORAGE';
export const RESET_STORAGE_FILE = 'RESET_STORAGE_FILE';
export const UPDATE_STORAGE_KEY_VALUE = 'UPDATE_STORAGE_KEY_VALUE';
export const UPDATE_STORAGE_GET_OBJECT = 'UPDATE_STORAGE_GET_OBJECT';
export const UPDATE_UPLOAD_PROGRESS = 'UPDATE_UPLOAD_PROGRESS';

// media
export const LISTEN_MEDIA_START = 'LISTEN_MEDIA_START';
export const LISTEN_MEDIA_UPDATE = 'LISTEN_MEDIA_UPDATE';
export const LISTEN_MEDIA_CREATE = 'LISTEN_MEDIA_CREATE';
export const LISTEN_MEDIA_DELETE = 'LISTEN_MEDIA_DELETE';
export const FETCH_MEDIA = 'FETCH_MEDIA';
export const RESET_MEDIA = 'RESET_MEDIA';
export const UPDATE_MEDIA_KEY_VALUE = 'UPDATE_MEDIA_KEY_VALUE';
export const UPDATE_MEDIA_GET_OBJECT = 'UPDATE_MEDIA_GET_OBJECT';

export const LISTEN_LAYOUT_START = 'LISTEN_LAYOUT_START';
export const LISTEN_LAYOUT_UPDATE = 'LISTEN_LAYOUT_UPDATE';
export const LISTEN_LAYOUT_CREATE = 'LISTEN_LAYOUT_CREATE';
export const LISTEN_LAYOUT_DELETE = 'LISTEN_LAYOUT_DELETE';
export const FETCH_LAYOUT = 'FETCH_LAYOUT';

export const LISTEN_PRESENTATIONS_START = 'LISTEN_PRESENTATIONS_START';
export const LISTEN_PRESENTATIONS_UPDATE = 'LISTEN_PRESENTATIONS_UPDATE';
export const LISTEN_PRESENTATIONS_CREATE = 'LISTEN_PRESENTATIONS_CREATE';
export const LISTEN_PRESENTATIONS_DELETE = 'LISTEN_PRESENTATIONS_DELETE';
export const FETCH_PRESENTATIONS = 'FETCH_PRESENTATIONS';

export const LISTEN_MEDIA_TYPES_START = 'LISTEN_MEDIA_TYPES_START';
export const LISTEN_MEDIA_TYPES_UPDATE = 'LISTEN_MEDIA_TYPES_UPDATE';
export const LISTEN_MEDIA_TYPES_CREATE = 'LISTEN_MEDIA_TYPES_CREATE';
export const LISTEN_MEDIA_TYPES_DELETE = 'LISTEN_MEDIA_TYPES_DELETE';
export const FETCH_MEDIA_TYPES = 'FETCH_MEDIA_TYPES';



// widgets
export const LISTEN_TEAMS_START = 'LISTEN_TEAMS_START';
export const LISTEN_TEAMS_UPDATE = 'LISTEN_TEAMS_UPDATE';
export const LISTEN_TEAMS_CREATE = 'LISTEN_TEAMS_CREATE';
export const LISTEN_TEAMS_DELETE = 'LISTEN_TEAMS_DELETE';
export const FETCH_TEAMS = 'FETCH_TEAMS';
export const RESET_TEAM = 'RESET_TEAM';
export const UPDATE_TEAM_KEY_VALUE = 'UPDATE_TEAM_KEY_VALUE';
export const UPDATE_TEAM_GET_OBJECT = 'UPDATE_TEAM_GET_OBJECT';

export const LISTEN_LISTS_START = 'LISTEN_LISTS_START';
export const LISTEN_LISTS_UPDATE = 'LISTEN_LISTS_UPDATE';
export const LISTEN_LISTS_CREATE = 'LISTEN_LISTS_CREATE';
export const LISTEN_LISTS_DELETE = 'LISTEN_LISTS_DELETE';
export const FETCH_LISTS = 'FETCH_LISTS';
export const RESET_LIST = 'RESET_LIST';
export const UPDATE_LIST_KEY_VALUE = 'UPDATE_LIST_KEY_VALUE';
export const UPDATE_LIST_GET_OBJECT = 'UPDATE_LIST_GET_OBJECT';

// form builder
export const LISTEN_FB_OPTIONS_START = 'LISTEN_FB_OPTIONS_START';
export const LISTEN_FB_OPTIONS_UPDATE = 'LISTEN_FB_OPTIONS_UPDATE';
export const LISTEN_FB_OPTIONS_CREATE = 'LISTEN_FB_OPTIONS_CREATE';
export const LISTEN_FB_OPTIONS_DELETE = 'LISTEN_FB_OPTIONS_DELETE';
export const FETCH_FB_OPTIONS = 'FETCH_FB_OPTIONS';
export const LISTEN_FB_OPTION_TYPES_START = 'LISTEN_FB_OPTION_TYPES_START';
export const LISTEN_FB_OPTION_TYPES_UPDATE = 'LISTEN_FB_OPTION_TYPES_UPDATE';
export const LISTEN_FB_OPTION_TYPES_CREATE = 'LISTEN_FB_OPTION_TYPES_CREATE';
export const LISTEN_FB_OPTION_TYPES_DELETE = 'LISTEN_FB_OPTION_TYPES_DELETE';
export const FETCH_FB_OPTION_TYPES = 'FETCH_FB_OPTION_TYPES';


// BI Realtime
export const LISTEN_BI_REALTIME_TICKETS = 'LISTEN_BI_REALTIME_TICKETS';
export const LISTEN_BI_REALTIME_SESSIONS = 'LISTEN_BI_REALTIME_SESSIONS';

// BI Realtime
export const GET_QM_BRANCHES = 'GET_QM_BRANCHES';
export const GET_CF_BRANCHES = 'GET_CF_BRANCHES';
export const GET_CF_TICKETS = 'GET_CF_TICKETS';
export const GET_FEEDBACK = 'GET_FEEDBACK';

// MANAGEMENT
export const FETCH_SETUP_INFO = 'FETCH_SETUP_INFO';
export const UPDATE_SUPPORT_DESK_ID = 'UPDATE_SUPPORT_DESK_ID';
export const SAVE_WINDOW_FOCUS = 'SAVE_WINDOW_FOCUS';

// Sessions
export const LISTEN_SESSIONS_START = 'LISTEN_SESSIONS_START';
export const LISTEN_SESSIONS_UPDATE = 'LISTEN_SESSIONS_UPDATE';
export const LISTEN_SESSIONS_CREATE = 'LISTEN_SESSIONS_CREATE';
export const LISTEN_SESSIONS_DELETE = 'LISTEN_SESSIONS_DELETE';
export const FETCH_SESSIONS = 'FETCH_SESSIONS';
export const CREATE_SESSION = 'CREATE_SESSION';
export const CREATE_SESSION_LOG = 'CREATE_SESSION_LOG';
export const UPDATE_SESSION_STATUS = 'UPDATE_SESSION_STATUS';
export const UPDATE_SESSION_SAVE_STATUS = 'UPDATE_SESSION_SAVE_STATUS';
export const UPDATE_FETCHED_SESSIONS_STATUS = 'UPDATE_FETCHED_SESSIONS_STATUS';

// Tickets
// get and listen to tickets
export const LISTEN_TICKETS_START = 'LISTEN_TICKETS_START';
export const LISTEN_TICKETS_CREATE = 'LISTEN_TICKETS_CREATE';
export const LISTEN_TICKETS_UPDATE = 'LISTEN_TICKETS_UPDATE';
export const LISTEN_TICKETS_DELETE = 'LISTEN_TICKETS_DELETE';
export const FETCH_TICKETS = 'FETCH_TICKETS';
export const UPDATE_ACTIVE_TICKET = 'UPDATE_ACTIVE_TICKET';
export const INCREASE_LISTEN_TICKET_COUNT = 'INCREASE_LISTEN_TICKET_COUNT';
export const SAVE_LATEST_TICKET = 'SAVE_LATEST_TICKET';
export const UPDATE_ACTIVE_MANAGEMENT_TICKET = 'UPDATE_ACTIVE_MANAGEMENT_TICKET';

// managing a ticket
export const CALL_TICKET = 'CALL_TICKET';
export const COMPLETE_TICKET = 'COMPLETE_TICKET';
export const TRANSFER_TICKET = 'TRANSFER_TICKET';
export const NO_SHOW_TICKET = 'NO_SHOW_TICKET';

// Sync total callTicekts made back to the app. 
// This is required an usefull for tracking how many times a ticket is called 
// and how long an agent waits before moving on. 
// Also allows other users to know which users are calling tickets
export const LISTEN_CALL_TICKETS_START = 'LISTEN_CALL_TICKETS_START';
export const LISTEN_CALL_TICKETS_CREATE = 'LISTEN_CALL_TICKETS_CREATE';
export const FETCH_CALL_TICKETS = 'FETCH_CALL_TICKETS';

// same as Sync total callTicekts but for transfers.
export const LISTEN_TRANSFER_TICKETS_START = 'LISTEN_TRANSFER_TICKETS_START';
export const LISTEN_TRANSFER_TICKETS_CREATE = 'LISTEN_TRANSFER_TICKETS_CREATE';
export const FETCH_TRANSFER_TICKETS = 'FETCH_TRANSFER_TICKETS';

// Customers
export const LISTEN_CUSTOMERS_START = 'LISTEN_CUSTOMERS_START';
export const LISTEN_CUSTOMERS_CREATE = 'LISTEN_CUSTOMERS_CREATE';
export const LISTEN_CUSTOMERS_UPDATE = 'LISTEN_CUSTOMERS_UPDATE';
export const LISTEN_CUSTOMERS_DELETE = 'LISTEN_CUSTOMERS_DELETE';
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';