import React from 'react';
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

// components
import AudioRecording from './activeTicket/AudioRecording';
import Time from './time/Time';
import TicketDetails from './activeTicket/TicketDetails';
import CustomerDetails from './activeTicket/CustomerDetails';


import {
    modalUpdate,
} from "../../../store/actions/settingsActions";

import {
    updateActiveManagementTicketId,
} from "../../../store/actions/ticketsActions";

const { getMyActiveTicket, getMyWaitingTickets } = require("../../../store/helpers");

class ActiveTicket extends React.Component {
    handleModalClick(id = null){
        const { modalUpdate, updateActiveManagementTicketId } = this.props
        id = id != null
            ?   id
            :   this.props.ticket.id
            
        updateActiveManagementTicketId(id)
        modalUpdate({ visible: 1, content: "TicketManagement", modalType: 1 })
    }

    render(){
        let { authenticatedUser, activeTicketId, tickets, supportDeskId } = this.props

        const activeTicket = getMyActiveTicket(tickets, activeTicketId);
        const nextTickets = getMyWaitingTickets(tickets, authenticatedUser, supportDeskId);

        const ticket = activeTicket != null ? activeTicket : nextTickets[0];

        return (
            <div className="home-time-supportDesk-top-wraper" style={{ border: activeTicket != null ? "6px solid #ffe163" : "" }}>
                {
                    activeTicket != null
                    ?   <>
                            <div className="ticketCutout" style={{background:"#ffe163"}}></div>
                            <div className="ticketCutout" style={{background:"#ffe163"}}></div>
                            <div className="ticketDash"></div>
                        </>
                    :   null
                }
                {
                    nextTickets.length
                        ?   <>
                                <div className="home-time-supportDesk-top">
                                    <div className="titleBlock">
                                        { activeTicket != null ? <h4>Current Ticket</h4> : <h4 style={{opacity:.3}}>Next Ticket</h4> } 
                                        <div className="home-time-supportDesk-with-btn">
                                            <div className="manageBtn" onClick={ () => this.handleModalClick(ticket.id) }>Manage</div>
                                            <div className="home-left-supportDesk">
                                                <h3>
                                                    {
                                                        activeTicket != null
                                                            ?   <Time created = { ticket.sessionStart } />
                                                            :   null
                                                    }
                                                </h3>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                                <div className="home-time-supportDesk-main">
                                    <>
                                        <TicketDetails ticket={ ticket } isActive={ activeTicket!= null ? 1 : 0 } />
                                        <CustomerDetails ticket={ ticket } />
                                    </>
                                </div>
                                {/* <AudioRecording /> */}
                            </>
                        : <div className="emptyQueue">
                            <div>Queue is empty</div>
                            <div>There are currently no customers in your queue</div>
                            <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M28 2L14.067 17.938L8.3445 12.2326M7.78469 18L2 12.2326M21.6555 2L13.756 11.0543" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
                            </svg>
                        </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { tickets, auth, sessions } = state
    return {
        tickets: tickets.tickets,
        sessions: sessions.sessions,
        activeTicketId: tickets.activeTicketId,
        authenticatedUser: auth.authenticatedUser,
        supportDeskId: auth.supportDeskId,
    }
  }
  
  export default connect(mapStateToProps, {
    modalUpdate,
    updateActiveManagementTicketId,
  })(ActiveTicket);
  


