import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom'

// components
import TicketDetails from '../../activeTicket/TicketDetails';
import CustomerDetails from '../../activeTicket/CustomerDetails';

import { PubSubKeys, TicketStatuses } from '../../../../../store/constants'
import { transferTicket } from '../../../../../store/actions/ticketsActions'
import {
    modalUpdate,
} from "../../../../../store/actions/settingsActions";

function transferTicker(navigate, props, exitInformation){
    const action = 'transferTicket'

    const payload = {
        ...PubSubKeys[action],
        msg: exitInformation
    }
    console.log(props);
    props.transferTicket(props.hostname, payload)
    props.modalUpdate({ visible: 0, content: null, modalType: 0 })
    return(
        navigate('/queuing/tickets/')
    )
}

// this component is for saving the transfer information of a ticket
function TransferExitInformation(props) {
    const navigate = useNavigate()
    const [formDetails, updateExitForm] = useState({ reason: 0 })
    
    const { tickets, supportDesks, sessions,fbOptions, activeTicketId, authenticatedUser } = props
    // get transfer info for the ticket. This is info for where the agent wants the ticket to go.
    const { transferInfo } = props
    // grab a copy of the changes made by the call to track to the current agent
    //  before the ticket is transfered
    const oldTicket = tickets.find( data => data.id === activeTicketId )

    // reset to the original ticket values, remove all changes made by the call
    // json stringify and parse

    let ticketReset = { ...JSON.parse(JSON.stringify(oldTicket))  }
    // reset status code to transfer code
    ticketReset.status = TicketStatuses.transferTicket
    ticketReset.transferStatus = 1
    delete ticketReset.manage
    delete ticketReset.volume
    delete ticketReset.sessionStart
    delete ticketReset.updated
    delete ticketReset.userId

    // make changes to the ticketReset based on the transfer type
    let manage = {}
    // service
    if( transferInfo.type === 'service')
        ticketReset.serviceId = transferInfo.id
    // agent
    if( transferInfo.type === 'agent') {
        manage.userId = transferInfo.id
    }

    // supportDesk
    if( transferInfo.type === 'supportDesk'){
        manage.supportDeskId = transferInfo.id
    }

    ticketReset.manage = manage

    const exitInformation = {
        ticket: ticketReset,
        oldTicket,
        transferType: transferInfo.type,
        transferAgent: authenticatedUser.id,
        formDetails,
        organizationId: props.organizationId,
        branchId: props.branchId,

    }

    const ticket = tickets.find( data => data.id === activeTicketId )
   
    return activeTicketId != null
        ?   <>
                <div className="select-options">
                    <label>Reason for transfer</label>
                    <div className="selectStyling">
                        <select value={formDetails.reason} onChange={ (e) => updateExitForm({ reason: e.target.value })}>
                            <option value="">Select a reason</option>
                            {
                                fbOptions
                                    .filter(data => data.visibility )
                                    .filter(data => data.optionTypeId === 'kYCTGnpXepmqwOWeVCzd')
                                    .map((data, index) => 
                                        <option key={index} value={ data.id }>{ data.name }</option>
                                )
                            }
                        </select>
                        {/* add textarea when other is selected to find other reason why users transfer tickets */}
                    </div>
                </div>
                <div className="home-right-content2-bottom">
                    <TicketDetails ticket = {ticket} />
                    <CustomerDetails ticket = {ticket} />
                </div>
                {
                    formDetails.reason.length
                        ?   <div className="form-submit-btn" onClick = { () => transferTicker(navigate, props, exitInformation) }>Yes, transfer ticket</div>
                        :   null
                }
            </>
        :   <h5>Ticket was successfully transferred</h5>


}

const mapStateToProps = state => {
    const { auth, tickets, sessions, services, fb } = state
    return {
        hostname: auth.hostname,
        organizationId: auth.organizationId,
        branchId: auth.branchId,
        tickets: tickets.tickets,
        activeTicketId: tickets.activeTicketId,
        supportDesks: services.supportDesks,
        sessions: sessions.sessions,
        services: services.services,
        authenticatedUser: auth.authenticatedUser,
        fbOptions: fb.fbOptions
    }
}

export default connect(mapStateToProps, { 
    transferTicket,
    modalUpdate
})(TransferExitInformation);