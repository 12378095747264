import axios from 'axios';
import dayjs from 'dayjs';
import { toast } from "react-toastify";
import io from "socket.io-client";
import store from '../index';

import {
  FETCH_AUDIENCES,
  FETCH_PRODUCTS,
  FETCH_TOWNS,
  FETCH_REGIONS,
  FETCH_COUNTRIES,
} from './types';

import { getAPI, toastActions } from '../helpers';

const api = getAPI();
const { REACT_APP_IS_DEV  } = process.env;

let socket = {}; // Declare a variable to hold the Socket.IO client instance
const listenToEvent = (hostname, eventName, updateType, organizationCheck = 0) => (dispatch) => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  socket[eventName] = io(baseURL);
  const startType = updateType + '_START';
  const payload = 1;
  dispatch({ type: startType, payload: 1 });
  socket[eventName].on(`${eventName}-updated`, (res) => {
    console.log(startType, "event", res);
    let type, payload;
    if (res.new_val != null) {
      type = updateType + (res.old_val != null ? '_UPDATE' : '_CREATE');
      payload = res.new_val;
    } else {
      type = updateType + '_DELETE';
      payload = res.old_val;
    }

    // dispatch only if the payload is for the active organization
      dispatch({
        type,
        payload
      })
  });
};

const listenToEventStop = (eventName, updateType) => (dispatch) => {
  if (socket[eventName]) {
    socket[eventName].off(`${eventName}-updated`);
    socket[eventName].disconnect();
    socket[eventName] = null;
  }
  const type = updateType + '_START';
  dispatch({ type, payload: 0 });
}

// Events
export const listenTowns = (hostname) =>
  listenToEvent(hostname, 'locale_towns', 'LISTEN_TOWNS', 0);
export const listenTownsStop = () =>
listenToEventStop('locale_towns', 'LISTEN_TOWNS');

export const listenRegions = (hostname) =>
  listenToEvent(hostname, 'locale_regions', 'LISTEN_REGIONS', 0);
export const listenRegionsStop = () =>
listenToEventStop('locale_regions', 'LISTEN_REGIONS');

export const listenCountries = (hostname) =>
  listenToEvent(hostname, 'locale_countries', 'LISTEN_COUNTRIES', 0);
export const listenCountriesStop = () =>
listenToEventStop('locale_countries', 'LISTEN_COUNTRIES');

// products
export const fetchProducts = (hostname, payload) => async (dispatch) => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  try {
    const res = await axios.get( baseURL + "/admin/products", payload);
    dispatch({
      type: FETCH_PRODUCTS,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};

// audience
export const fetchAudience = (hostname, payload) => async (dispatch) => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  try {
    const res = await axios.get( baseURL + "/admin/audience", payload);
    dispatch({
      type: FETCH_AUDIENCES,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};

// town
export const fetchTowns = (hostname, payload) => async (dispatch) => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  try {
    const res = await axios.get( baseURL + "/locale/towns", payload);
    dispatch({
      type: FETCH_TOWNS,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};

// region
export const fetchRegions = (hostname, payload) => async (dispatch) => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  try {
    const res = await axios.get( baseURL + "/locale/regions", payload);
    dispatch({
      type: FETCH_REGIONS,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};

// country
export const fetchCountries = (hostname, payload) => async (dispatch) => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  try {
    const res = await axios.get( baseURL + "/locale/countries", payload);
    dispatch({
      type: FETCH_COUNTRIES,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};
