import React from 'react';
import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

class TimeFormatter extends React.Component {
    render(){
        const { created } = this.props
        return dayjs.unix(created).tz("Africa/Windhoek").format("HH:mm");
    }
}

export default TimeFormatter