import axios from 'axios';
import dayjs from 'dayjs';
import { toast } from "react-toastify";
import io from "socket.io-client";
import store from '../index';

import { getAPI } from '../helpers';

import { 
  FETCH_USERS,
} from './types';

const api = getAPI()
const { REACT_APP_IS_DEV  } = process.env;

let socket = {}; // Declare a variable to hold the Socket.IO client instance
const listenToEvent = (hostname, eventName, updateType) => (dispatch) => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  socket[eventName] = io(baseURL);
  const startType = updateType + '_START';
  const payload = 1;
  dispatch({ type: startType, payload: 1 });
  socket[eventName].on(`${eventName}-updated`, (res) => {
    console.log(startType, "event", res);
    let type, payload;
    if (res.new_val != null) {
      type = updateType + (res.old_val != null ? '_UPDATE' : '_CREATE');
      payload = res.new_val;
    } else {
      type = updateType + '_DELETE';
      payload = res.old_val;
    }


    const { authenticatedUser } = store.getState().auth;
    if(authenticatedUser.branchId === payload.branchId)
    {
      dispatch({
        type,
        payload
      })
    }
    
  });
};

const listenToEventStop = (eventName, updateType) => (dispatch) => {
  if (socket[eventName]) {
    socket[eventName].off(`${eventName}-updated`);
    socket[eventName].disconnect();
    socket[eventName] = null;
  }
  const type = updateType + '_START';
  dispatch({ type, payload: 0 });
}

// Events
export const listenUsers = (hostname) =>
  listenToEvent(hostname, 'iam_users', 'LISTEN_USERS', 1);
export const listenUsersStop = () =>
listenToEventStop('iam_users', 'LISTEN_USERS');

export const fetchUsers = (hostname, organizationId) => dispatch => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  axios
  .get( baseURL + "/iam/users", { params: { organizationId, agentStatus: 1 } })
  .then(res => {
    dispatch({
      type: FETCH_USERS,
      payload: res.data
    })
  })
  .catch(err => {
    console.log(err)
  })
}