import { combineReducers } from 'redux';
import settingsReducer from './settingsReducer';
import usersReducer from './usersReducer';
import organizationsReducer from './organizationsReducer';
import branchesReducer from './branchesReducer';
import servicesReducer from './servicesReducer';
import miscReducer from './miscReducer';
import authReducer from './authReducer';

import sessionsReducer from './sessionsReducer';
import ticketsReducer from './ticketsReducer';
import customersReducer from './customersReducer';
import managementReducer from './managementReducer';
import formBuilderReducer from './formBuilderReducer';

const reducer = combineReducers({
  auth: authReducer,
  organizations: organizationsReducer,
  branches: branchesReducer,
  services: servicesReducer,
  users: usersReducer,
  misc: miscReducer,
  sessions: sessionsReducer,
  tickets: ticketsReducer,
  customers: customersReducer,
  management: managementReducer,
  settings: settingsReducer,
  fb: formBuilderReducer,
});

export default reducer