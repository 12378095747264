import { 
  FETCH_ORGANIZATIONS,
  LISTEN_ORGANIZATIONS_START, 
  LISTEN_ORGANIZATIONS_UPDATE,  
  LISTEN_ORGANIZATIONS_CREATE,  
  LISTEN_ORGANIZATIONS_DELETE,  
  RESET_ORGANIZATION,
  UPDATE_ORGANIZATION_KEY_VALUE,
  UPDATE_ORGANIZATION_GET_OBJECT,
  CREATE_ORGANIZATION,
  DELETE_ORGANIZATION,
} from '../actions/types';


const initialState = {
  listenOrganizationsStart: 0,
  organizations: [],
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case LISTEN_ORGANIZATIONS_START:
      return {
        ...state,
        listenOrganizationsStart: action.payload
      };
    case LISTEN_ORGANIZATIONS_CREATE:
      return {
        ...state,
        organizations: [...state.organizations, action.payload]
          .filter((data, index, array) => array
            .findIndex(data2 => data2.id === data.id) === index)
          .sort((a, b) => a.name
            .localeCompare(b.name))
      };
    case LISTEN_ORGANIZATIONS_UPDATE:
      return {
        ...state,
        organizations: state.organizations.map(data => {
          if (data.id === action.payload.id) { return action.payload }
          return data;
        })
      };
    case LISTEN_ORGANIZATIONS_DELETE:
      return {
        ...state,
        organizations: state.organizations.filter(data => data.id !== action.payload.id )
      };
    case FETCH_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload
      };
    default:
      return state;
  }
}