import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import throttle from 'lodash/throttle';

import App from './App';
import store from './store/';
import { saveState } from './store/LocalStorage';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

store.subscribe(throttle(() => {
  console.log("Update state");
  // resets for reload
  const auth = store.getState().auth;
  const users = store.getState().users;
  const organizations = store.getState().organizations;
  const branches = store.getState().branches;
  const services = store.getState().services;
  const sessions = store.getState().sessions;
  const tickets = store.getState().tickets;
  const customers = store.getState().customers;
  const misc = store.getState().misc;
  const settings = store.getState().settings;
  const fb = store.getState().fb;

  // saveState
  saveState({ organizations, branches, services, users, auth, sessions, tickets, customers, misc, settings, fb  });
}, 1000));

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
