import React from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// actions
import {
  fetchCloudOrganizations,
  fetchCloudBranches,
  updateSetupStatus,
  updateAppStatus,
  updateTokenStatus,
  tokenStatusCheck,
  unAuthenticateUser,
  fetchSetupInfo
} from './store/actions/authActions';
import {
  listenOrganizations,
  fetchOrganizations,
  listenOrganizationsStop,
} from './store/actions/organizationsActions';
import { listenUsers, fetchUsers, listenUsersStop } from './store/actions/usersActions';
import { listenBranches, fetchBranches, listenBranchesStop } from './store/actions/branchesActions';

import {
  listenServices,
  fetchServices,
  listenServicesStop,
  listenServiceSubscriptions,
  fetchServiceSubscriptions,
  listenServiceSubscriptionsStop,
  listenSupportDesks,
  fetchSupportDesks,
  listenSupportDesksStop,
  listenPriorityCustomers,
  fetchPriorityCustomers,
  listenPriorityCustomersStop,
  listenPriorityCustomersSubscriptions,
  fetchPriorityCustomersSubscriptions,
  listenPriorityCustomersSubscriptionsStop,
} from './store/actions/servicesActions';
import {
  
  listenTowns,
  fetchTowns,
  listenTownsStop,
  listenRegions,
  fetchRegions,
  listenRegionsStop,
  listenCountries,
  fetchCountries,
  listenCountriesStop,
} from './store/actions/miscActions';
import {
  listenFbOptions,
  listenFbOptionTypes,
  listenFbOptionsStop,
  fetchFbOptions,
  fetchFbOptionTypes,
  listenFbOptionTypesStop,

} from './store/actions/formBuilderActions';
import {
  listenSessions,
  listenSessionsStop,
  fetchSessions,
  createSession,
  updateSessionStatus,
  updateSessionSaveStatus,
  updateSessionsFetchedStatus,
  updateListenSessionsStart,
} from "./store/actions/sessionsActions";
import {
  listenTickets,
  listenTicketsStop,
  fetchTickets,
  listenCallTickets,
  listenCallTicketsStop,
  fetchCallTickets,
  listenTransferTickets,
  listenTransferTicketsStop,
  fetchTransferTickets,
} from "./store/actions/ticketsActions";
import {
  listenCustomers,
  listenCustomersStop,
  fetchCustomers,
  updateListenCustomersStart,
} from "./store/actions/customersActions";

// pages
import Setup from "./pages/Setup";
import SignIn from './pages/SignIn';
import Tickets from "./pages/Tickets";
import Analytics from "./pages/Analytics";
import Agents from "./pages/Agents";
import SignOut from "./pages/SignOut";
import Components from "./pages/Components";
import WindowFocusHandler from "./pages/WindowFocusHandler";


import "./assets/css/Layout.css";
import Modal1 from "./components/modals/Modal1";

class App extends React.Component {
  componentDidMount() {
    // check tokenStatus, this is only checked when an authenticatedUsers reloads the tab
    if (Object.keys(this.props.authenticatedUser).length && this.props.tokenStatus === -1) {
      this.props.tokenStatusCheck(this.props.hostname, this.props.authenticatedUser.accessToken);
    }
    // 
    // fetch cloud organizations
    if( this.props.setupStatus !== 1 ) { 
      this.props.fetchCloudOrganizations(); 
    }


    if (!navigator.serviceWorker.controller) {
      navigator.serviceWorker.register('/service-worker.js', {
        type: 'module',
        periodicSync: {
          name: 'check-for-updates',
          tag: 'check-for-updates',
          minPeriod: 60, // Check every hour
        },
      })
        .then((registration) => console.log('Service worker registered:', registration))
        .catch((err) => console.error('Service worker registration failed:', err));
    }

  }

  componentDidUpdate(prevProps) {
    // start fetches so they can wait for the listeners to be started
    this.listenersFetchesStart(prevProps);

    // get cloud organizations after hostname / setupStatus reset
    if(
      (prevProps.setupStatus === 1
      && this.props.setupStatus === -1
      )
      || (prevProps.hostname.length
        && !this.props.hostname.length
      )
    ) {
      this.props.fetchCloudOrganizations(); 
    }

    // when hostname is available, fetchSetupInfo
    if(
      !prevProps.hostname.length
      && this.props.hostname.length
    ) {
      this.props.fetchSetupInfo(this.props.hostname);
    }
    
    // update setup status when organizationId and branchId are set
    if (
      !prevProps.organizationId.length
      && this.props.organizationId.length
      && !prevProps.branchId.length
      && this.props.branchId.length
    ) {
      this.props.updateSetupStatus(1);
    }

    // login
    // supportDesks
    if (prevProps.setupStatus !== 1 && this.props.setupStatus === 1) {
      this.props.listenSupportDesks(this.props.hostname);
    }

    // create a new session and save (only when it's a new login and not a reload)
    if (!Object.keys(prevProps.authenticatedUser).length && Object.keys(this.props.authenticatedUser).length) {
      this.props.updateTokenStatus(1);
    }
    // create session and save to db
    if( prevProps.tokenStatus === -1 && this.props.tokenStatus === 1 ) {
      console.log(this.props.sessionSavedToDB);
      if( !this.props.sessionSavedToDB ) {
        //create session for the agent that is logged in
        const sessionData = {
          userId: this.props.authenticatedUser.id,
          id: this.props.authenticatedUser.id,
          supportDeskId: this.props.supportDeskId,
          status: 1,
          reason: "",
          description: "",
          organizationId: this.props.organizationId,
          branchId: this.props.branchId
        };
        this.props.createSession(this.props.hostname, sessionData);
      }
      else {
        // update status in the event it's only a erload and session was already created in the app and saved to db
        this.props.updateSessionSaveStatus(1);
      }
    }

    // listenersStart
    if( !prevProps.sessionSavedToDB && this.props.sessionSavedToDB ) {
      this.listenersStart();
    }
    // validate the authenticated user and session
    // after the session has been fetched from the db
    if( !prevProps.sessionsFetchedStatus && this.props.sessionsFetchedStatus ) {
      this.checkAuthenticatedUserAndSession()
    }

    if( prevProps.sessionStatus === -1 && this.props.sessionStatus === 1 ) {
      this.props.updateAppStatus(1);
    }

    // signout tokenStatus change
    if (prevProps.tokenStatus === 1 && this.props.tokenStatus === -1) {
      // unauthenticate agent
      this.resetAndUnAuthenticate();
    }
    // expired token, tokenStatus change
    if (prevProps.tokenStatus === -1 && this.props.tokenStatus === 0) {
      // unauthenticate agent
      this.resetAndUnAuthenticate();
    }

    // reload app
    if (
      prevProps.reloadAppStatus !== this.props.reloadAppStatus
      && this.props.reloadAppStatus
    ) {
      // we can do a fresh fetch and restart all listeners
      // this.fetchStart();
      console.log('reload');
      // for now we will just do a reload
      window.location.reload();
    }
  }

  listenersStart() {
    const { hostname } = this.props;
    console.log('listenersStart');
    this.props.listenOrganizations(hostname);

    this.props.listenUsers(hostname);
    this.props.listenBranches(hostname);

    this.props.listenTowns(hostname);
    this.props.listenRegions(hostname);
    this.props.listenCountries(hostname);

    this.props.listenServices(hostname);
    this.props.listenServiceSubscriptions(hostname);
    // this.props.listenSupportDesks(hostname);
    this.props.listenPriorityCustomers(hostname);
    this.props.listenPriorityCustomersSubscriptions(hostname);

    this.props.listenFbOptions(hostname);
    this.props.listenFbOptionTypes(hostname);

    this.props.listenSessions(hostname);
    this.props.listenTickets(hostname);
    this.props.listenCustomers(hostname);
    this.props.listenCallTickets(hostname);
    this.props.listenTransferTickets(hostname);
  }

  // Check if listeners were created and fetch data
  listenersFetchesStart(prevProps) {
    console.log('listenersFetchesStart');
    const { hostname } = this.props;
    // Fetch organizations
    if (
      prevProps.listenOrganizationsStart === 0
      && this.props.listenOrganizationsStart === 1
    ) {
      this.props.fetchOrganizations(hostname);
    }
    // Fetch users
    if (
      prevProps.listenUsersStart === 0
      && this.props.listenUsersStart === 1
    ) {
      this.props.fetchUsers(hostname, this.props.organizationId);
    }
    // Fetch branches
    if (
      prevProps.listenBranchesStart === 0
      && this.props.listenBranchesStart === 1
    ) {
      this.props.fetchBranches(hostname, this.props.organizationId);
    }

    // Fetch towns
    if (
      prevProps.listenTownsStart === 0
      && this.props.listenTownsStart === 1
    ) {
      this.props.fetchTowns(hostname);
    }
    // Fetch regions
    if (
      prevProps.listenRegionsStart === 0
      && this.props.listenRegionsStart === 1
    ) {
      this.props.fetchRegions(hostname);
    }
    // Fetch countries
    if (
      prevProps.listenCountriesStart === 0
      && this.props.listenCountriesStart === 1
    ) {
      this.props.fetchCountries(hostname);
    }
    // Fetch services
    if (
      prevProps.listenServicesStart === 0
      && this.props.listenServicesStart === 1
    ) {
      this.props.fetchServices(hostname, this.props.organizationId);
    }
    // Fetch serviceSubscriptions
    if (
      prevProps.listenServiceSubscriptionsStart === 0
      && this.props.listenServiceSubscriptionsStart === 1
    ) {
      this.props.fetchServiceSubscriptions(hostname, this.props.branchId);
    }
    // Fetch supportDesks
    if (
      prevProps.listenSupportDesksStart === 0
      && this.props.listenSupportDesksStart === 1
    ) {
      this.props.fetchSupportDesks(hostname, this.props.organizationId);
    }
    // Fetch priorityCustomers
    if (
      prevProps.listenPriorityCustomersStart === 0
      && this.props.listenPriorityCustomersStart === 1
    ) {
      this.props.fetchPriorityCustomers(hostname);
    }
    // Fetch priorityCustomersSubscriptions
    if (
      prevProps.listenPriorityCustomerSubscriptionsStart === 0
      && this.props.listenPriorityCustomerSubscriptionsStart === 1
    ) {
      this.props.fetchPriorityCustomersSubscriptions(hostname, this.props.organizationId);
    }
    // Fetch Tickets
    if (
      prevProps.listenSessionsStart === 0
      && this.props.listenSessionsStart === 1
    ) {
      this.props.fetchSessions(hostname, this.props.organizationId);
    }
    // Fetch Tickets
    if (
      prevProps.listenTicketsStart === 0
      && this.props.listenTicketsStart === 1
    ) {
      console.log('fetchTickets', this.props.authenticatedUser);
      this.props.fetchTickets(hostname, this.props.organizationId);
    }
    // Fetch customers
    if (
      prevProps.listenCustomersStart === 0
      && this.props.listenCustomersStart === 1
    ) {
      this.props.fetchCustomers(hostname, this.props.organizationId);
    }
    // Fetch callTickets
    if (
      prevProps.listenCallTicketsStart === 0
      && this.props.listenCallTicketsStart === 1
    ) {
      this.props.fetchCallTickets(hostname, this.props.organizationId);
    }
    // Fetch transferTickets
    if (
      prevProps.listenTransferTicketsStart === 0
      && this.props.listenTransferTicketsStart === 1
    ) {
      this.props.fetchTransferTickets(hostname, this.props.organizationId);
    }
    // Fetch fbOptions
    if (
      prevProps.listenFbOptionsStart === 0
      && this.props.listenFbOptionsStart === 1
    ) {
      console.log("XXXXX Update options");
      this.props.fetchFbOptions(hostname);
    }
    // Fetch ffbOptionTypes
    if (
      prevProps.listenFbOptionTypesStart === 0
      && this.props.listenFbOptionTypesStart === 1
    ) {
      console.log("XXXXX Update options");
      this.props.fetchFbOptionTypes(hostname);
    }
    
 
  }

  checkAuthenticatedUserAndSession(){
    console.log('checkAuthenticatedUserAndSession');
    const { authenticatedUser, sessions } = this.props
    // increament the error count if there is an issue. 
      // anything greater than zero will result in logging the user out
      let appStatusErrors = 0

      // check if authenticatedUser is populated
      appStatusErrors = !(Object.keys(authenticatedUser).length)
        ? appStatusErrors + 1
        : appStatusErrors

      // make sure user is authenticated
      appStatusErrors = !( Number(authenticatedUser.status) )
        ? appStatusErrors + 1
        : appStatusErrors

      // make sure user session is available
      if( !appStatusErrors ) {
        let userSession = sessions.filter( session => session.userId === authenticatedUser.id )
        appStatusErrors = !(userSession.length)
          ? appStatusErrors + 1
          : appStatusErrors

        appStatusErrors = userSession.length
          ? !(Number( userSession[0].status ))
              ? appStatusErrors + 1
              : appStatusErrors
          : appStatusErrors + 1
      }
      // issue with session. Logout agent
      const status = appStatusErrors ? 0 : 1
      console.log('status', status, appStatusErrors);
      this.props.updateSessionStatus(status)
  }

  resetAndUnAuthenticate() {
    this.props.unAuthenticateUser();
    this.props.updateAppStatus(0);
    this.props.updateTokenStatus(-1);
    this.props.updateSessionSaveStatus(0);
    this.props.updateSessionStatus(-1);
    this.props.updateSessionsFetchedStatus(0);

    this.props.listenOrganizationsStop();

    this.props.listenBranchesStop();

    this.props.listenCustomersStop();
    this.props.listenFbOptionsStop();
    this.props.listenFbOptionTypesStop();
    this.props.listenTownsStop();
    this.props.listenRegionsStop();
    this.props.listenCountriesStop();

    this.props.listenServicesStop();
    this.props.listenServiceSubscriptionsStop();
    this.props.listenSupportDesksStop();
    this.props.listenPriorityCustomersStop();
    this.props.listenPriorityCustomersSubscriptionsStop();

    this.props.listenSessionsStop();

    this.props.listenTicketsStop();
    this.props.listenCallTicketsStop();
    this.props.listenTransferTicketsStop();

    this.props.listenUsersStop();
    
  }

  render() {

    const { setupStatus, tokenStatus, sessionSavedToDB, sessionStatus, appStatus, authenticatedUser, sessionsFetchedStatus } = this.props

    const routerContent = this.props.setupStatus !== 1 ? (
      <Router>
        <ToastContainer />
        <Routes>
          <Route path="/setup/" element={<Setup />} />
          <Route path="*" element={<Navigate to="/setup/" replace />} />
        </Routes>
      </Router>
    ) : !this.props.appStatus ? (
      <Router>
        <ToastContainer />
        <Routes>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="*" element={<Navigate to="/sign-in" />} />
        </Routes>
      </Router>
    ) : (
      <Router>
        <ToastContainer />
        <Modal1 />
        
        <WindowFocusHandler />
        <Routes>
          <Route path="/queuing/tickets/" element={<Tickets />} />
          <Route path="/queuing/analytics/" element={<Analytics />} />
          <Route path="/queuing/agents/" element={<Agents />} />
          <Route path="/sign-out/" element={<SignOut />} />
          <Route path="/components/" element={<Components />} />

          <Route path="/" element={ <Navigate to="/queuing/tickets/" replace />} />
          <Route path="/sign-in/" element={ <Navigate to="/queuing/tickets/" replace />} />
          <Route path="/queuing/" element={ <Navigate to="/queuing/tickets/" replace />} />
          <Route path="*" element={<Navigate to="/apps/" replace />} />
        </Routes>
      </Router>
    );
    return routerContent;
  }
}

const mapStateToProps = (state) => {
  const {
    auth, organizations, users, branches, services, misc, sessions, tickets, customers, fb
  } = state;
  return {
    cloudOrganizations: auth.cloudOrganizations,
    cloudBranches: auth.cloudBranches,
    hostname: auth.hostname,
    setupStatus: auth.setupStatus,
    tokenStatus: auth.tokenStatus,
    appStatus: auth.appStatus,
    authenticatedUser: auth.authenticatedUser,
    reloadAppStatus: auth.reloadAppStatus,
    organizationId: auth.organizationId,
    branchId: auth.branchId,
    supportDeskId: auth.supportDeskId,

    listenUsersStart: users.listenUsersStart,
    user: users.user,
    userAuthenticated: users.userAuthenticated,

    listenOrganizationsStart: organizations.listenOrganizationsStart,
    listenBranchesStart: branches.listenBranchesStart,

    listenTownsStart: misc.listenTownsStart,
    listenRegionsStart: misc.listenRegionsStart,
    listenCountriesStart: misc.listenCountriesStart,

    listenServicesStart: services.listenServicesStart,
    listenServiceSubscriptionsStart: services.listenServiceSubscriptionsStart,
    listenSupportDesksStart: services.listenSupportDesksStart,
    listenPriorityCustomersStart: services.listenPriorityCustomersStart,
    listenPriorityCustomerSubscriptionsStart: services.listenPriorityCustomerSubscriptionsStart,

    listenFbOptionsStart: fb.listenFbOptionsStart,
    listenFbOptionTypesStart: fb.listenFbOptionTypesStart,

    listenSessionsStart: sessions.listenSessionsStart,
    sessions: sessions.sessions,
    sessionsFetchedStatus: sessions.sessionsFetchedStatus,
    sessionSavedToDB: sessions.sessionSavedToDB,
    sessionStatus: sessions.sessionStatus,

    listenTicketsStart: tickets.listenTicketsStart,
    listenCallTicketsStart: tickets.listenCallTicketsStart,
    listenTransferTicketsStart: tickets.listenTransferTicketsStart,
    listenCustomersStart: customers.listenCustomersStart,
  };
};

export default connect(mapStateToProps, {
  fetchCloudOrganizations,
  fetchCloudBranches,
  updateSetupStatus,
  updateTokenStatus,
  updateAppStatus,
  tokenStatusCheck,
  unAuthenticateUser,
  fetchSetupInfo,

  listenOrganizations,
  fetchOrganizations,
  listenOrganizationsStop,

  listenUsers,
  fetchUsers,
  listenUsersStop,

  listenBranches,
  fetchBranches,
  listenBranchesStop,

  listenTowns,
  fetchTowns,
  listenTownsStop,
  listenRegions,
  fetchRegions,
  listenRegionsStop,
  listenCountries,
  fetchCountries,
  listenCountriesStop,

  listenServices,
  fetchServices,
  listenServicesStop,
  listenServiceSubscriptions,
  fetchServiceSubscriptions,
  listenServiceSubscriptionsStop,
  listenSupportDesks,
  fetchSupportDesks,
  listenSupportDesksStop,
  listenPriorityCustomers,
  fetchPriorityCustomers,
  listenPriorityCustomersStop,
  listenPriorityCustomersSubscriptions,
  fetchPriorityCustomersSubscriptions,
  listenPriorityCustomersSubscriptionsStop,
  
  listenFbOptions,
  listenFbOptionTypes,
  listenFbOptionsStop,
  fetchFbOptions,
  fetchFbOptionTypes,
  listenFbOptionTypesStop,

  listenTickets,
  listenTicketsStop,
  fetchTickets,
  listenCallTickets,
  listenCallTicketsStop,
  fetchCallTickets,
  listenTransferTickets,
  listenTransferTicketsStop,
  fetchTransferTickets,
  listenCustomers,
  listenCustomersStop,
  fetchCustomers,
  updateListenCustomersStart,

  listenSessions,
  listenSessionsStop,
  fetchSessions,
  createSession,
  updateSessionStatus,
  updateSessionSaveStatus,
  updateSessionsFetchedStatus,
  updateListenSessionsStart,


})(App);
