import {
  FETCH_CLOUD_ORGANIZATIONS,
  FETCH_CLOUD_BRANCHES,
  UPDATE_ORGANIZATION_ID,
UPDATE_BRANCH_ID,
UPDATE_HOST_NAME,
  UPDATE_SUPPORT_DESK_ID,
  AUTHENTICATE_USER,
  UPDATE_SETUP_STATUS,
  UPDATE_APP_STATUS,
  UPDATE_TOKEN_STATUS,
  UNAUTHENTICATE_USER,
  AUTHENTICATION_ERROR,
  UPDATE_ACTIVE_ORGANIZATION,
  UPDATE_RELOAD_APP_STATUS,
  FETCH_SETUP_INFO
} from '../actions/types';

const initialState = {
  setupStatus: -1, // whether the app is setup or not. -1=no done yet, 0=failed, 1=success
  tokenStatus: -1, // token used for validating server session and api requests
  appStatus: 0, // whether app is in logged in status and required qpi requests have been made or not
  reloadAppStatus: 0, // when 1 app will reload and will be auto set back to 0

  cloudOrganizations: [],
  cloudBranches: [],
  organizationId: '',
  branchId: '',
  hostname: '',
  supportDeskId: '',

  authenticatedUser: {},
  authenticationError: 0,
  errorNotificationId: null
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case FETCH_CLOUD_ORGANIZATIONS:
      return {
        ...state,
        cloudOrganizations: action.payload,
      };
    case FETCH_CLOUD_BRANCHES:
      return {
        ...state,
        cloudBranches: action.payload,
      };
    case UPDATE_ORGANIZATION_ID:
      return {
        ...state,
        organizationId: action.payload
      };
    case UPDATE_BRANCH_ID:
      return {
        ...state,
        branchId: action.payload
      };
    case UPDATE_HOST_NAME:
      return {
        ...state,
        hostname: action.payload
      };
    case FETCH_SETUP_INFO:
      return {
        ...state,
        organizationId: action.payload.status ? action.payload.organizationId : '',
        branchId: action.payload.status ? action.payload.branchId : '',
      };
    case UPDATE_SUPPORT_DESK_ID:
      return {
        ...state,
        supportDeskId: action.payload
      };
    case AUTHENTICATE_USER:
      return {
        ...state,
        authenticatedUser: action.payload,
        authenticationError: 0,
        errorNotificationId: null
      };
    case UPDATE_APP_STATUS:
      return {
        ...state,
        appStatus: action.payload,
      };
    case UPDATE_SETUP_STATUS:
      return {
        ...state,
        setupStatus: action.payload,
      };
    case UPDATE_TOKEN_STATUS:
      return {
        ...state,
        tokenStatus: action.payload,
      };
    
    case AUTHENTICATION_ERROR:
      return {
        ...state,
        authenticatedUser: {},
        appStatus: 0,
        tokenStatus: -1,
        authenticationError: state.authenticationError + 1,
        errorNotificationId: action.payload.errorNotificationId
      };
    case UNAUTHENTICATE_USER:
      return {
        ...state,
        authenticatedUser: {},
        authenticationError: 0,
      };
    case UPDATE_ACTIVE_ORGANIZATION:
      return {
        ...state,
        authenticatedUser: { ...state.authenticatedUser, activeOrganization: action.payload }
      }
    case UPDATE_RELOAD_APP_STATUS:
      return {
        ...state,
        reloadAppStatus: action.payload
      }
    
    default:
      return state;
  }
}