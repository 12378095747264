import axios from 'axios';
import io from "socket.io-client";
import store from '../index';

import { 
  FETCH_SERVICES,
  FETCH_SERVICE_SUBSCRIPTIONS,
  FETCH_SUPPORT_DESKS,
  FETCH_PRIORITY_CUSTOMERS,
  FETCH_PRIORITY_CUSTOMER_SUBSCRIPTIONS,

} from './types';

import { getAPI, toastActions } from '../helpers';

const api = getAPI();
const { REACT_APP_IS_DEV  } = process.env;

let socket = {}; // Declare a variable to hold the Socket.IO client instance
const listenToEvent = (hostname, eventName, updateType, organizationCheck = 0) => (dispatch) => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  socket[eventName] = io(baseURL);
  const startType = updateType + '_START';
  const payload = 1;
  dispatch({ type: startType, payload: 1 });
  socket[eventName].on(`${eventName}-updated`, (res) => {
    console.log(startType, "event", res);
    let type, payload;
    if (res.new_val != null) {
      type = updateType + (res.old_val != null ? '_UPDATE' : '_CREATE');
      payload = res.new_val;
    } else {
      type = updateType + '_DELETE';
      payload = res.old_val;
    }

    // dispatch only if the payload is for this branch
    const { authenticatedUser } = store.getState().auth;
    if(authenticatedUser.branchId === payload.branchId)
    {
      dispatch({
        type,
        payload
      })
    }
  });
};

const listenToEventStop = (eventName, updateType) => (dispatch) => {
  if (socket[eventName]) {
    socket[eventName].off(`${eventName}-updated`);
    socket[eventName].disconnect();
    socket[eventName] = null;
  }
  const type = updateType + '_START';
  dispatch({ type, payload: 0 });
}

// Events
export const listenServices = (hostname) =>
  listenToEvent(hostname, 'apps_qm_services', 'LISTEN_SERVICES', 1);
export const listenServicesStop = () =>
listenToEventStop('apps_qm_services', 'LISTEN_SERVICES');

export const listenServiceSubscriptions = (hostname) =>
  listenToEvent(hostname, 'apps_qm_serviceSubscriptions', 'LISTEN_SERVICE_SUBSCRIPTIONS', 1);
export const listenServiceSubscriptionsStop = () =>
listenToEventStop('apps_qm_serviceSubscriptions', 'LISTEN_SERVICE_SUBSCRIPTIONS');

export const listenSupportDesks = (hostname) =>
  listenToEvent(hostname, 'apps_qm_supportDesks', 'LISTEN_SUPPORT_DESKS', 1);
export const listenSupportDesksStop = () =>
listenToEventStop('apps_qm_supportDesks', 'LISTEN_SUPPORT_DESKS');

export const listenPriorityCustomers = (hostname) =>
  listenToEvent(hostname, 'apps_qm_priorityCustomers', 'LISTEN_PRIORITY_CUSTOMERS', 0);
export const listenPriorityCustomersStop = () =>
listenToEventStop('apps_qm_priorityCustomers', 'LISTEN_PRIORITY_CUSTOMERS');

export const listenPriorityCustomersSubscriptions = (hostname) =>
  listenToEvent(hostname, 'apps_qm_priorityCustomers', 'LISTEN_PRIORITY_CUSTOMER_SUBSCRIPTIONS', 1);
export const listenPriorityCustomersSubscriptionsStop = () =>
listenToEventStop('apps_qm_priorityCustomers', 'LISTEN_PRIORITY_CUSTOMER_SUBSCRIPTIONS');




// services
export const fetchServices = (hostname, organizationId) => async (dispatch) => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  try {
    const res = await axios.get( baseURL + "/apps/qm/services", { params: { organizationId } });
    dispatch({
      type: FETCH_SERVICES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

// service subscriptions
export const fetchServiceSubscriptions = (hostname, organizationId) => dispatch => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  axios
  .get( baseURL + "/apps/qm/serviceSubscriptions", { params: { organizationId } })
  .then(res => {
    dispatch({
      type: FETCH_SERVICE_SUBSCRIPTIONS,
      payload: res.data
    })
  })
  .catch(err => {
    console.log(err)
  })
}

// Support Desks
export const fetchSupportDesks = (hostname, organizationId) => async (dispatch) => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  try {
    const res = await axios.get( baseURL + "/apps/qm/supportDesks", { params: { organizationId } });
    dispatch({
      type: FETCH_SUPPORT_DESKS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

// Support Priority Customers
export const fetchPriorityCustomers = (hostname) => async (dispatch) => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  try {
    const res = await axios.get( baseURL + "/apps/qm/priorityCustomers");
    dispatch({
      type: FETCH_PRIORITY_CUSTOMERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

// Priority Customers Subscriptions
export const fetchPriorityCustomersSubscriptions = (hostname, organizationId) => async (dispatch) => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  try {
    const res = await axios.get( baseURL + "/apps/qm/priorityCustomerSubscriptions", { params: { organizationId } });
    dispatch({
      type: FETCH_PRIORITY_CUSTOMER_SUBSCRIPTIONS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

