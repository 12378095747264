import { 
  LISTEN_FB_OPTIONS_START,
  LISTEN_FB_OPTIONS_UPDATE,
  LISTEN_FB_OPTIONS_CREATE,
  LISTEN_FB_OPTIONS_DELETE,
  FETCH_FB_OPTIONS,
  LISTEN_FB_OPTION_TYPES_START,
  LISTEN_FB_OPTION_TYPES_UPDATE,
  LISTEN_FB_OPTION_TYPES_CREATE,
  LISTEN_FB_OPTION_TYPES_DELETE,
  FETCH_FB_OPTION_TYPES,
} from '../actions/types';

const initialState = {
  listenFbOptionsStart: 0,
  listenFbOptionTypesStart: 0,
  fbOptions: [],
  fbOptionTypes: [],
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case LISTEN_FB_OPTIONS_START:
      return {
        ...state,
        listenFbOptionsStart: action.payload
      };
    case LISTEN_FB_OPTIONS_CREATE:
      return {
        ...state,
        fbOptions: ([ ...state.fbOptions, action.payload ]).filter((data, index, array) => array.findIndex( data2 => ( data2.id === data.id ) ) === index ).sort((a, b) => a.listOrder - b.listOrder)
      };
    case LISTEN_FB_OPTIONS_UPDATE:
      return {
        ...state,
        fbOptions: state.fbOptions.map(data => {
          if (data.id === action.payload.id) { return action.payload }
          return data;
        })
      };
    case LISTEN_FB_OPTIONS_DELETE:
      return {
        ...state,
        fbOptions: state.fbOptions.filter(data => data.id !== action.payload.id )
      };
    case FETCH_FB_OPTIONS:
      return {
        ...state,
        fbOptions: action.payload.sort((a, b) => a.listOrder - b.listOrder)

      };
    
    case LISTEN_FB_OPTION_TYPES_START:
      return {
        ...state,
        listenFbOptionTypesStart: action.payload
      };
    case LISTEN_FB_OPTION_TYPES_CREATE:
      return {
        ...state,
        fbOptionTypes: ([ ...state.fbOptionTypes, action.payload ]).filter((data, index, array) => array.findIndex( data2 => ( data2.id === data.id ) ) === index )
      };
    case LISTEN_FB_OPTION_TYPES_UPDATE:
      return {
        ...state,
        fbOptionTypes: state.fbOptionTypes.map(data => {
          if (data.id === action.payload.id) { return action.payload }
          return data;
        })
      };
    case LISTEN_FB_OPTION_TYPES_DELETE:
      return {
        ...state,
        fbOptionTypes: state.fbOptionTypes.filter(data => data.id !== action.payload.id )
      };
    case FETCH_FB_OPTION_TYPES:
      return {
        ...state,
        fbOptionTypes: action.payload

      };
    
    default:
      return state;
  }
}