import axios from 'axios'
import io from "socket.io-client";
import { toast } from "react-toastify";
import { 
  LISTEN_TICKETS_START,
  LISTEN_TICKETS_CREATE,
  LISTEN_TICKETS_UPDATE,
  LISTEN_TICKETS_DELETE,
  FETCH_TICKETS,
  UPDATE_ACTIVE_TICKET,
  CALL_TICKET,
  COMPLETE_TICKET,
  TRANSFER_TICKET,
  NO_SHOW_TICKET,
  LISTEN_CALL_TICKETS_START,
  LISTEN_CALL_TICKETS_CREATE,
  LISTEN_TRANSFER_TICKETS_START,
  LISTEN_TRANSFER_TICKETS_CREATE,
  FETCH_CALL_TICKETS,
  FETCH_TRANSFER_TICKETS,
  INCREASE_LISTEN_TICKET_COUNT,
  SAVE_LATEST_TICKET,
  UPDATE_ACTIVE_MANAGEMENT_TICKET,
} from './types';
import store from '../../store';

import { getAPI } from '../helpers';

const api = getAPI();
const { REACT_APP_IS_DEV  } = process.env;

let socket = {}; // Declare a variable to hold the Socket.IO client instance
const listenToEvent = (hostname, eventName, updateType) => (dispatch) => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  socket[eventName] = io(baseURL);
  const startType = updateType + '_START';
  dispatch({ type: startType, payload: 1 });
  socket[eventName].on(`${eventName}-updated`, (res) => {
    console.log(startType, "event", res);
    let type, payload;
    if (res.new_val != null) {
      type = updateType + (res.old_val != null ? '_UPDATE' : '_CREATE');
      payload = res.new_val;
    } else {
      type = updateType + '_DELETE';
      payload = res.old_val;
    }
    dispatch({ type, payload })

    if(type === 'LISTEN_TICKETS_CREATE'){
      dispatch({
        type: INCREASE_LISTEN_TICKET_COUNT,
        payload: res.new_val
      })
      dispatch({
        type: SAVE_LATEST_TICKET,
        payload: res.new_val
      })
    }

  });
};

const listenToEventStop = (eventName, updateType) => (dispatch) => {
  if (socket[eventName]) {
    socket[eventName].off(`${eventName}-updated`);
    socket[eventName].disconnect();
    socket[eventName] = null;
  }
  const type = updateType + '_START';
  dispatch({ type, payload: 0 });
}

// Events
export const listenTickets = (hostname) =>
  listenToEvent(hostname, 'management_tickets', 'LISTEN_TICKETS');
export const listenTicketsStop = () =>
listenToEventStop('management_tickets', 'LISTEN_TICKETS');

export const listenCallTickets = (hostname) =>
  listenToEvent(hostname, 'management_callTickets', 'LISTEN_CALL_TICKETS');
export const listenCallTicketsStop = () =>
listenToEventStop('management_callTickets', 'LISTEN_CALL_TICKETS');

export const listenTransferTickets = (hostname) =>
  listenToEvent(hostname, 'management_transferTickets', 'LISTEN_TRANSFER_TICKETS');
export const listenTransferTicketsStop = () =>
listenToEventStop('management_transferTickets', 'LISTEN_TRANSFER_TICKETS');

export const fetchTickets = (hostname) => dispatch => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  const auth = store.getState().auth
  const { authenticatedUser } = auth
  const { accessToken } = authenticatedUser
  
  axios.get( 
    baseURL + '/management/tickets/',
    {
      headers: {
        'Authorization': `Basic ${accessToken}`
      }
    } 
  )
  .then(res =>
    dispatch({
      type: FETCH_TICKETS,
      payload: res.data
    })
  )
  .catch(err => console.log(err) )
};
export const updateListenTicketsStart = (payload) => dispatch => {
  dispatch({
    type: LISTEN_TICKETS_START,
    payload
  })
};
export const callTicket = (hostname, payload) => dispatch => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  axios.post( baseURL + '/localrmq/', payload )
  .then(res =>
    dispatch({
      type: CALL_TICKET,
      payload: res.data
    })
  )
  .catch(err => console.log(err) )
};

export const updateActiveTicket = payload => dispatch => {
  dispatch({
    type: UPDATE_ACTIVE_TICKET,
    payload
  })
};

// ticket actions
export const completeTicket = (hostname, payload) => dispatch => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  axios.post( baseURL + '/localrmq/', payload)
  .then(res => {
    dispatch( updateActiveTicket({id: null}) )
    dispatch({
      type: COMPLETE_TICKET,
      payload: res.data
    })
  })
  .catch(err => console.log(err) )
};

export const noShowTicket = (hostname, payload) => dispatch => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  axios.post( baseURL + '/localrmq/', payload)
  .then(res => {
    dispatch( updateActiveTicket({id: null}) )
    dispatch({
      type: NO_SHOW_TICKET,
      payload: res.data
    })
  })
  .catch(err => console.log(err) )
};

export const transferTicket = (hostname, payload) => dispatch => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  axios.post( baseURL + '/localrmq/', payload )
  .then(res => {
    const { ticketLetter, ticketNumber } = payload.msg.ticket
    toast.success('Ticket: ' + ticketLetter + '-' + ticketNumber + ', has been successfully transferred' );
    dispatch( updateActiveTicket({id: null}) )
    dispatch({
      type: TRANSFER_TICKET,
      payload: res.data
    })
  })
  .catch(err => console.log(err) )
};

export const updateListenCallTicketsSyncStart = (payload) => dispatch => {
  dispatch({
    type: LISTEN_CALL_TICKETS_START,
    payload
  })
};

export const fetchCallTickets = (hostname) => dispatch => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  axios.get( baseURL + '/management/callTickets/' )
  .then(res =>
    dispatch({
      type: FETCH_CALL_TICKETS,
      payload: res.data
    })
  )
  .catch(err => console.log(err) )
};

export const updateListenTransferTicketsSyncStart = (payload) => dispatch => {
  dispatch({
    type: LISTEN_TRANSFER_TICKETS_START,
    payload
  })
};

export const fetchTransferTickets = (hostname) => dispatch => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  axios.get( baseURL + '/management/transferTickets/' )
  .then(res =>
    dispatch({
      type: FETCH_TRANSFER_TICKETS,
      payload: res.data
    })
  )
  .catch(err => console.log(err) )
};

export const resetLatestTicket = () => dispatch => {
  dispatch({
    type: SAVE_LATEST_TICKET,
    payload: null
  })
};

export const updateActiveManagementTicketId = (payload) => dispatch => {
  dispatch({
    type: UPDATE_ACTIVE_MANAGEMENT_TICKET,
    payload
  })
};