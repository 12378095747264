import { 
  LISTEN_AUDIENCES_START,
  LISTEN_AUDIENCES_UPDATE,
  LISTEN_AUDIENCES_CREATE,
  LISTEN_AUDIENCES_DELETE,
  FETCH_AUDIENCES,

  LISTEN_PRODUCTS_START,
  LISTEN_PRODUCTS_UPDATE,
  LISTEN_PRODUCTS_CREATE,
  LISTEN_PRODUCTS_DELETE,
  FETCH_PRODUCTS,

  LISTEN_TOWNS_START,
  LISTEN_TOWNS_UPDATE,
  LISTEN_TOWNS_CREATE,
  LISTEN_TOWNS_DELETE,
  FETCH_TOWNS,
  RESET_TOWN,
  UPDATE_TOWN_KEY_VALUE,
  UPDATE_TOWN_GET_OBJECT,

  LISTEN_REGIONS_START,
  LISTEN_REGIONS_UPDATE,
  LISTEN_REGIONS_CREATE,
  LISTEN_REGIONS_DELETE,
  FETCH_REGIONS,

  LISTEN_COUNTRIES_START,
  LISTEN_COUNTRIES_UPDATE,
  LISTEN_COUNTRIES_CREATE,
  LISTEN_COUNTRIES_DELETE,
  FETCH_COUNTRIES,
} from '../actions/types';


const initialState = {
  listenAudienceStart: 0,
  listenProductsStart: 0,
  listenTownsStart: 0,
  listenRegionsStart: 0,
  listenCountriesStart: 0,
  
  audiences: [],    
  products: [],
  
  towns: [],

  regions: [],
  countries: [],
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case LISTEN_AUDIENCES_START:
      return {
        ...state,
        listenAudienceStart: action.payload
      };
    case LISTEN_AUDIENCES_CREATE:
      return {
        ...state,
        audiences: ([ ...state.audiences, action.payload ]).filter((data, index, array) => array.findIndex( data2 => ( data2.id === data.id ) ) === index )
      };
    case LISTEN_AUDIENCES_UPDATE:
      return {
        ...state,
        audiences: state.audiences.map(data => {
          if (data.id === action.payload.id) { return action.payload }
          return data;
        })
      };
    case LISTEN_AUDIENCES_DELETE:
      return {
        ...state,
        audiences: state.audiences.filter(data => data.id !== action.payload.id )
      };
    case FETCH_AUDIENCES:
      return {
        ...state,
        audiences: action.payload
      };
    case LISTEN_PRODUCTS_START:
      return {
        ...state,
        listenProductsStart: action.payload
      };
    case LISTEN_PRODUCTS_CREATE:
      return {
        ...state,
        products: ([ ...state.products, action.payload ]).filter((data, index, array) => array.findIndex( data2 => ( data2.id === data.id ) ) === index )
      };
    case LISTEN_PRODUCTS_UPDATE:
      return {
        ...state,
        products: state.products.map(data => {
          if (data.id === action.payload.id) { return action.payload }
          return data;
        })
      };
    case LISTEN_PRODUCTS_DELETE:
      return {
        ...state,
        products: state.products.filter(data => data.id !== action.payload.id )
      };
    case FETCH_PRODUCTS:
      return {
        ...state,
        products: action.payload
      };
    case LISTEN_TOWNS_START:
      return {
        ...state,
        listenTownsStart: action.payload
      };
    case LISTEN_TOWNS_CREATE:
      return {
        ...state,
        towns: ([ ...state.towns, action.payload ]).filter((data, index, array) => array.findIndex( data2 => ( data2.id === data.id ) ) === index )
      };
    case LISTEN_TOWNS_UPDATE:
      return {
        ...state,
        towns: state.towns.map(data => {
          if (data.id === action.payload.id) { return action.payload }
          return data;
        })
      };
    case LISTEN_TOWNS_DELETE:
      return {
        ...state,
        towns: state.towns.filter(data => data.id !== action.payload.id )
      };
    case FETCH_TOWNS:
      return {
        ...state,
        towns: action.payload
      };
    case LISTEN_REGIONS_START:
      return {
        ...state,
        listenRegionsStart: action.payload
      };
    case LISTEN_REGIONS_CREATE:
      return {
        ...state,
        regions: ([ ...state.regions, action.payload ]).filter((data, index, array) => array.findIndex( data2 => ( data2.id === data.id ) ) === index )
      };
    case LISTEN_REGIONS_UPDATE:
      return {
        ...state,
        regions: state.regions.map(data => {
          if (data.id === action.payload.id) { return action.payload }
          return data;
        })
      };
    case LISTEN_REGIONS_DELETE:
      return {
        ...state,
        regions: state.regions.filter(data => data.id !== action.payload.id )
      };
    case FETCH_REGIONS:
      return {
        ...state,
        regions: action.payload
      };
    case LISTEN_COUNTRIES_START:
      return {
        ...state,
        listenCountriesStart: action.payload
      };
    case LISTEN_COUNTRIES_CREATE:
      return {
        ...state,
        countries: ([ ...state.countries, action.payload ]).filter((data, index, array) => array.findIndex( data2 => ( data2.id === data.id ) ) === index )
      };
    case LISTEN_COUNTRIES_UPDATE:
      return {
        ...state,
        countries: state.countries.map(data => {
          if (data.id === action.payload.id) { return action.payload }
          return data;
        })
      };
    case LISTEN_COUNTRIES_DELETE:
      return {
        ...state,
        countries: state.countries.filter(data => data.id !== action.payload.id )
      };
    case FETCH_COUNTRIES:
      return {
        ...state,
        countries: action.payload
      };

    default:
      return state;
  }
}