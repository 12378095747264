import { 
  LISTEN_BRANCHES_START,
  LISTEN_BRANCHES_UPDATE,
  LISTEN_BRANCHES_CREATE,
  LISTEN_BRANCHES_DELETE,
  FETCH_BRANCHES,
  UPDATE_BRANCH,
  RESET_BRANCH,
  UPDATE_BRANCH_KEY_VALUE,
  UPDATE_BRANCH_GET_OBJECT
} from '../actions/types';


const initialState = {
  listenBranchesStart: 0,
  branches: [],
};

export default function reducerCase( state = initialState, action ) {
  switch (action.type) {
    case LISTEN_BRANCHES_START:
      return {
        ...state,
        listenBranchesStart: action.payload
      };
    case LISTEN_BRANCHES_CREATE:
      return {
        ...state,
        branches: ([ ...state.branches, action.payload ]).filter((data, index, array) => array.findIndex( data2 => ( data2.id === data.id ) ) === index )
      };
    case LISTEN_BRANCHES_UPDATE:
      return {
        ...state,
        branches: state.branches.map(data => {
          if (data.id === action.payload.id) { return action.payload }
          return data;
        })
      };
    case LISTEN_BRANCHES_DELETE:
      return {
        ...state,
        branches: state.branches.filter(data => data.id !== action.payload.id )
      };
    case FETCH_BRANCHES:
      return {
        ...state,
        branches: action.payload
      };
    default:
      return state;
  }
}