import { 
  LISTEN_SERVICES_START,
  LISTEN_SERVICES_UPDATE,
  LISTEN_SERVICES_CREATE,
  LISTEN_SERVICES_DELETE,
  FETCH_SERVICES,
  RESET_SERVICE,
  UPDATE_SERVICE_KEY_VALUE,
  UPDATE_SERVICE_GET_OBJECT,

  LISTEN_SERVICE_SUBSCRIPTIONS_START,
  LISTEN_SERVICE_SUBSCRIPTIONS_UPDATE,
  LISTEN_SERVICE_SUBSCRIPTIONS_CREATE,
  LISTEN_SERVICE_SUBSCRIPTIONS_DELETE,
  FETCH_SERVICE_SUBSCRIPTIONS,
  RESET_SERVICE_SUBSCRIPTION,
  PARTIAL_RESET_SERVICE_SUBSCRIPTION,
  UPDATE_SERVICE_SUBSCRIPTION_KEY_VALUE,
  UPDATE_SERVICE_SUBSCRIPTION_GET_OBJECT,

  LISTEN_SUPPORT_DESKS_START,
  LISTEN_SUPPORT_DESKS_UPDATE,
  LISTEN_SUPPORT_DESKS_CREATE,
  LISTEN_SUPPORT_DESKS_DELETE,
  FETCH_SUPPORT_DESKS,

  LISTEN_PRIORITY_CUSTOMERS_START,
  LISTEN_PRIORITY_CUSTOMERS_UPDATE,
  LISTEN_PRIORITY_CUSTOMERS_CREATE,
  LISTEN_PRIORITY_CUSTOMERS_DELETE,
  FETCH_PRIORITY_CUSTOMERS,

  LISTEN_PRIORITY_CUSTOMER_SUBSCRIPTIONS_START,
  LISTEN_PRIORITY_CUSTOMER_SUBSCRIPTIONS_UPDATE,
  LISTEN_PRIORITY_CUSTOMER_SUBSCRIPTIONS_CREATE,
  LISTEN_PRIORITY_CUSTOMER_SUBSCRIPTIONS_DELETE,
  FETCH_PRIORITY_CUSTOMER_SUBSCRIPTIONS,

  RESET_SUPPORT_DESK,
  UPDATE_SUPPORT_DESK_KEY_VALUE,
  UPDATE_SUPPORT_DESK_GET_OBJECT,

} from '../actions/types';


const initialState = {
  listenServicesStart: 0,
  listenServiceSubscriptionsStart: 0,
  listenSupportDesksStart: 0,
  listenPriorityCustomersStart: 0,
  listenPriorityCustomerSubscriptionsStart: 0,
  services: [],
  serviceLetters: ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"],

  serviceSubscriptions: [],

  supportDesks: [],

  priorityCustomers: [],
  priorityCustomerSubscriptions: [],
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case LISTEN_SERVICES_START:
      return {
        ...state,
        listenServicesStart: action.payload
      };
    case LISTEN_SERVICES_CREATE:
      return {
        ...state,
        services: ([ ...state.services, action.payload ]).filter((data, index, array) => array.findIndex( data2 => ( data2.id === data.id ) ) === index )
      };
    case LISTEN_SERVICES_UPDATE:
      return {
        ...state,
        services: state.services.map(data => {
          if (data.id === action.payload.id) { return action.payload }
          return data;
        })
      };
    case LISTEN_SERVICES_DELETE:
      return {
        ...state,
        services: state.services.filter(data => data.id !== action.payload.id )
      };
    case FETCH_SERVICES:
      return {
        ...state,
        services: action.payload
      };
    case LISTEN_SERVICE_SUBSCRIPTIONS_START:
      return {
        ...state,
        listenServiceSubscriptionsStart: action.payload
      };
    case LISTEN_SERVICE_SUBSCRIPTIONS_CREATE:
      return {
        ...state,
        serviceSubscriptions: ([ ...state.serviceSubscriptions, action.payload ]).filter((data, index, array) => array.findIndex( data2 => ( data2.id === data.id ) ) === index )
      };
    case LISTEN_SERVICE_SUBSCRIPTIONS_UPDATE:
      return {
        ...state,
        serviceSubscriptions: state.serviceSubscriptions.map(data => {
          if (data.id === action.payload.id) { return action.payload }
          return data;
        })
      };
    case LISTEN_SERVICE_SUBSCRIPTIONS_DELETE:
      return {
        ...state,
        serviceSubscriptions: state.serviceSubscriptions.filter(data => data.id !== action.payload.id )
      };
    case FETCH_SERVICE_SUBSCRIPTIONS:
      return {
        ...state,
        serviceSubscriptions: action.payload
      };
    case LISTEN_SUPPORT_DESKS_START:
      return {
        ...state,
        listenSupportDesksStart: action.payload
      };
    case LISTEN_SUPPORT_DESKS_CREATE:
      return {
        ...state,
        supportDesks: ([ ...state.supportDesks, action.payload ]).filter((data, index, array) => array.findIndex( data2 => ( data2.id === data.id ) ) === index )
      };
    case LISTEN_SUPPORT_DESKS_UPDATE:
      return {
        ...state,
        supportDesks: state.supportDesks.map(data => {
          if (data.id === action.payload.id) { return action.payload }
          return data;
        })
      };
    case LISTEN_SUPPORT_DESKS_DELETE:
      return {
        ...state,
        supportDesks: state.supportDesks.filter(data => data.id !== action.payload.id )
      };
    case FETCH_SUPPORT_DESKS:
      return {
        ...state,
        supportDesks: action.payload
      };
    


    


    case LISTEN_PRIORITY_CUSTOMERS_START:
      return {
        ...state,
        listenPriorityCustomersStart: action.payload
      };
    case LISTEN_PRIORITY_CUSTOMERS_CREATE:
      return {
        ...state,
        priorityCustomers: ([ ...state.priorityCustomers, action.payload ]).filter((data, index, array) => array.findIndex( data2 => ( data2.id === data.id ) ) === index )
      };
    case LISTEN_PRIORITY_CUSTOMERS_UPDATE:
      return {
        ...state,
        priorityCustomers: state.priorityCustomers.map(data => {
          if (data.id === action.payload.id) { return action.payload }
          return data;
        })
      };
    case LISTEN_PRIORITY_CUSTOMERS_DELETE:
      return {
        ...state,
        priorityCustomers: state.priorityCustomers.filter(data => data.id !== action.payload.id )
      };
    case FETCH_PRIORITY_CUSTOMERS:
      return {
        ...state,
        priorityCustomers: action.payload
      };

    



    case LISTEN_PRIORITY_CUSTOMER_SUBSCRIPTIONS_START:
      return {
        ...state,
        listenPriorityCustomerSubscriptionsStart: action.payload
      };
    case LISTEN_PRIORITY_CUSTOMER_SUBSCRIPTIONS_CREATE:
      return {
        ...state,
        priorityCustomerSubscriptions: ([ ...state.priorityCustomerSubscriptions, action.payload ]).filter((data, index, array) => array.findIndex( data2 => ( data2.id === data.id ) ) === index )
      };
    case LISTEN_PRIORITY_CUSTOMER_SUBSCRIPTIONS_UPDATE:
      return {
        ...state,
        priorityCustomerSubscriptions: state.priorityCustomerSubscriptions.map(data => {
          if (data.id === action.payload.id) { return action.payload }
          return data;
        })
      };
    case LISTEN_PRIORITY_CUSTOMER_SUBSCRIPTIONS_DELETE:
      return {
        ...state,
        priorityCustomerSubscriptions: state.priorityCustomerSubscriptions.filter(data => data.id !== action.payload.id )
      };
    case FETCH_PRIORITY_CUSTOMER_SUBSCRIPTIONS:
      return {
        ...state,
        priorityCustomerSubscriptions: action.payload
      };

    default:
      return state;
  }
}