import axios from 'axios';
import dayjs from 'dayjs';
import { toast } from "react-toastify";
import io from "socket.io-client";
import store from '../index';

import { getAPI } from '../helpers';

import { 
  FETCH_FB_OPTIONS,
  FETCH_FB_OPTION_TYPES,
} from './types';

const api = getAPI();
const { REACT_APP_IS_DEV  } = process.env;

let socket = {}; // Declare a variable to hold the Socket.IO client instance
const listenToEvent = (hostname, eventName, updateType, organizationCheck = 0) => (dispatch) => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  socket[eventName] = io(baseURL);
  const startType = updateType + '_START';
  dispatch({ type: startType, payload: 1 });
  socket[eventName].on(`${eventName}-updated`, (res) => {
    console.log(startType, "event", res);
    let type, payload;
    if (res.new_val != null) {
      type = updateType + (res.old_val != null ? '_UPDATE' : '_CREATE');
      payload = res.new_val;
    } else {
      type = updateType + '_DELETE';
      payload = res.old_val;
    }

    dispatch({
      type,
      payload
    })
    
  });
};

const listenToEventStop = (eventName, updateType) => (dispatch) => {
  if (socket[eventName]) {
    socket[eventName].off(`${eventName}-updated`);
    socket[eventName].disconnect();
    socket[eventName] = null;
  }
  const type = updateType + '_START';
  dispatch({ type, payload: 0 });
}

// Events
export const listenFbOptions = (hostname) =>
  listenToEvent(hostname, 'apps_fb_options', 'LISTEN_FB_OPTIONS', 1);
export const listenFbOptionsStop = () =>
listenToEventStop('apps_fb_options', 'LISTEN_FB_OPTIONS');

export const listenFbOptionTypes = (hostname) =>
  listenToEvent(hostname, 'apps_fb_optionTypes', 'LISTEN_FB_OPTION_TYPES', 1);
export const listenFbOptionTypesStop = () =>
listenToEventStop('apps_fb_optionTypes', 'LISTEN_FB_OPTION_TYPES');

export const fetchFbOptions = (hostname) => dispatch => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  axios
  .get( baseURL + "/apps/fb/options")
  .then(res => {
    dispatch({
      type: FETCH_FB_OPTIONS,
      payload: res.data
    })
  })
  .catch(err => {
    console.log(err)
  })
}

export const fetchFbOptionTypes = (hostname) => dispatch => {
  const baseURL = Number(REACT_APP_IS_DEV) ? api : `https://${hostname}:4000`;
  axios
  .get( baseURL + "/apps/fb/optionTypes")
  .then(res => {
    dispatch({
      type: FETCH_FB_OPTION_TYPES,
      payload: res.data
    })
  })
  .catch(err => {
    console.log(err)
  })
}