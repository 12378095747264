import React from 'react';
import { connect } from 'react-redux'

class CalledTicketNumber extends React.Component {
    render(){
        const { callTickets, activeTicketId, authenticatedUser } = this.props 
        if( activeTicketId == null ) return null       
        const tickets = callTickets.filter( data =>
            data.manage.userId === authenticatedUser.id 
            && data.ticketId === activeTicketId
        )

        return  tickets.length
        ?   (
                <div className="calledTicketNumber">
                    <div>called</div>
                    <div>{ tickets.length }</div>
                </div>
            )
        : null
    }
}

const mapStateToProps = state => {
    const { auth, tickets } = state
    return {
        callTickets: tickets.callTickets,
        activeTicketId: tickets.activeTicketId,
        authenticatedUser: auth.authenticatedUser,
    }
}

export default connect(mapStateToProps, { })(CalledTicketNumber);