import React from 'react';
import { connect } from 'react-redux'

class QuickAlerts extends React.Component {
    render(){
        let { authenticatedUser, tickets, sessions } = this.props
        const session = sessions.find( session => session.userId === authenticatedUser.id )

        let priorityTickets = tickets.filter( ticket => 
            ticket.manage != null &&
            !(Number(ticket.status))
        )
        .filter( ticket => 
            ticket.manage.userId === authenticatedUser.id ||
            ticket.manage.supportDeskId === session.supportDeskId

        )
        
        return priorityTickets.length
            ?   <div className="quickAlerts scheme-pinkred-bg">
                    <p className="count"><strong>{ priorityTickets.length } Ticket{priorityTickets.length>1?"s":""}</strong></p>
                    <p>can only be served by you</p>
                </div>
            :   null
    }
}

const mapStateToProps = state => {
    const { auth, tickets, sessions } = state
    return {
        authenticatedUser: auth.authenticatedUser,
        sessions: sessions.sessions,
        activeTicketId: tickets.activeTicketId,
        tickets: tickets.tickets,
    }
}

export default connect(mapStateToProps, {})(QuickAlerts);

