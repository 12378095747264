import React from 'react';
import { connect } from 'react-redux';

// components

function MyServices(props){

    const { authenticatedUser, services } = props
    const myServices = services.length
    ? authenticatedUser
        .services.map( ( data, index ) => {
            return <div key={ index }>{ services
                .find( service => service.id === data).name }
            </div>
})
    : <div>There are no services allocated to you.</div>

    return (
        <div className="sidebar-footer">
            <h5>My Support Services</h5>
            { myServices }
        </div>
    )
}


const mapStateToProps = state => {
    return {
        authenticatedUser: state.auth.authenticatedUser,
        services: state.services.services
    }
}
  
export default connect(mapStateToProps, {})(MyServices);