import React, { useState } from 'react';
import { connect } from 'react-redux';
import TransferExitInformation from './TransferExitInformation';
import _ from 'lodash';

// this component is used for transfers.
function Agents(props){
    const [transferInfo, transferTicket] = useState('default')
    const { users, sessions, supportDesks, authenticatedUser } = props
    const sessionsClone = _.cloneDeep(sessions);
    const userIds = users.map((a)=>a.id);
    const validSessions = sessionsClone.filter((a)=>userIds.includes(a.userId));

    var onlineAgents = []
    var offlineAgents = []
    
    users.forEach(data => {
        var session = validSessions.find( session => (session.userId === data.id))
        if(!_.isEmpty(session) && data.id !== authenticatedUser.id){
            if( Number(session.status)===1 ){
                var supportDesk = supportDesks.find(data => data.id === session.supportDeskId)
                var supportDeskType = supportDesk.type
                var supportDeskNumber = supportDesk.number != null ? supportDesk.number : ""
                onlineAgents.push({ ...data, supportDeskId: session.supportDeskId, supportDeskType, supportDeskNumber })
            } else {
                offlineAgents.push({ ...data, reason: session.reason })
            }
        } else {
            offlineAgents.push({ ...data, reason: "Never signed in" })
        }
    })
    
    switch (transferInfo.case){
        case 'exit':
        return (<div className="home-right-content1">
            <div className="titleBlock icon">
                <div className="iconWrapper" onClick={ () => transferTicket({case: 'default' }) }><ion-icon name="arrow-back-outline"></ion-icon></div>
                <h5>Transfer to Agent</h5>
                <h4>Back</h4>
            </div>
            <TransferExitInformation transferInfo={transferInfo} returnFunction={transferTicket} />
        </div>);

        default:
        return(
            <div className="home-left-content5-wraper">
                <div className="titleBlock icon">
                    <div className="iconWrapper" onClick={ () => props.returnFunction('default')}><ion-icon name="arrow-back-outline"></ion-icon></div>
                    <h5>{onlineAgents.length} Other Agent{ onlineAgents.length !== 1 ? 's' : '' } Online</h5>
                    <h5>Back</h5>
                </div>
                <div className="usersListContainer">
                    <div className="user-list-online">
                    {onlineAgents.map((data, index) => {

                        var inititals = data.name
                        .replace(/[&\/\\#,+()$~%.'":*?!<>{}]/g,'')
                        .charAt(0) 
                        + 
                        data.lastname
                        .replace(/[&\/\\#,+()$~%.'":*?!<>{}]/g,'')
                        .charAt(0)
                        
                        return(
                            <div className="user-list" key={index} onClick={ () => transferTicket({ case: 'exit', type:'agent', id: data.id }) }>
                                <div className="user-name-and-pic">
                                    <div>
                                        <div>
                                            <span className="status-badge"></span>
                                            <span className="user-image-text">{ inititals }</span> 
                                        </div>
                                        <p>{ data.name + " " + data.lastname}</p>
                                    </div>
                                </div>
                                <div className="user-list-content"><p>{ data.supportDeskType + " " + data.supportDeskNumber }</p></div>
                                <div className="user-list-check"><ion-icon name="checkmark-circle-outline"></ion-icon></div>
                            </div>
                        )
                        })}
                    </div>

                    <div className="user-list-offline">
                    {offlineAgents.map((data, index) => {

                        var inititals = data.name
                        .replace(/[&/\\#,+()$~%.'":*?!<>{}]/g,'')
                        .charAt(0) 
                        + 
                        data.lastname
                        .replace(/[&/\\#,+()$~%.'":*?!<>{}]/g,'')
                        .charAt(0)

                        return(
                            <div className="user-list" key={index} onClick={ () => transferTicket({ case: 'exit', type:'agent', id: data.id }) }>
                                <div className="user-name-and-pic">
                                    <div>
                                        <div>
                                            <span className="status-badge"></span>
                                            <span className="user-image-text">{ inititals }</span> 
                                        </div>
                                        <p>{ data.name + " " + data.lastname}</p>
                                    </div>
                                </div>
                                <div className="user-list-content"><p>{data.reason}</p></div>
                                <div className="user-list-check"><ion-icon name="checkmark-circle-outline"></ion-icon></div>
                            </div>
                        )
                        })}
                    </div>

                </div>
                

            </div>
        )
                    }

}

const mapStateToProps = state => {
    const { auth, users, sessions, services } = state
    return {
        users: users.users,
        authenticatedUser: auth.authenticatedUser,
        sessions: sessions.sessions,
        supportDesks: services.supportDesks,
    }
}
export default connect(mapStateToProps, {})(Agents);