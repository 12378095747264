export const getAPI = () => {
    // local api config
    const { REACT_APP_API_HOST_DEV, REACT_APP_API_HOST_PROD, REACT_APP_PORT_DEV, REACT_APP_PORT_PROD, REACT_APP_IS_DEV  } = process.env;

    // api config
    return Number(REACT_APP_IS_DEV)
    ? `https://${REACT_APP_API_HOST_DEV}:${REACT_APP_PORT_DEV}`
    : `https://${REACT_APP_API_HOST_PROD}:${REACT_APP_PORT_PROD}`;
}

export const toastActions = {
    "add": ["Adding", "adding", "added"], 
    "update": ["Updating", "updating", "updated"], 
    "delete": ["Deleting", "deleting", "deleted"]
}
export const getMyActiveTicket = (tickets, activeTicketId) => {
    // find ticket where ticket.id === activeTicketId
    const activeTicket = tickets.find( a => a.id === activeTicketId );
    // stringify and parse
    return activeTicket != null ? JSON.parse(JSON.stringify(activeTicket)) : null;
}
export const getAllActiveTickets = (tickets) => {
    return Array.from(
        tickets.filter( a => Number(a.status) === 1 )
    );
}
export const getAllWaitingTickets = (tickets, singleTicket = 0) => {
    // get waiting tickets
    const allWaitingTickets = Array.from(
        tickets.filter( a => Number(a.status) === 0 )
    );
    // get priority customers first and filter out null objects using boolean
    const waitingPriorityCustomers = allWaitingTickets.filter( a => a.priority ? a : null ).filter(Boolean);
    // sort priority customers by listOrder in descending order
    waitingPriorityCustomers.sort((a, b) => b.priority - a.priority);

    // remove the waitingPriorityCustomers from waitingTickets
    let waitingTickets = allWaitingTickets.filter( a => !a.priority );

    return [ ...waitingPriorityCustomers, ...waitingTickets];
}
export const getMyWaitingTickets = (tickets, authenticatedUser, supportDeskId) => {
    const userId = authenticatedUser.id;
    const services = authenticatedUser.services;
    const waitingTickets = [];

    // create a copy of tickets
    const ticketsCopy = Array.from(tickets);
    // find my active tickets
    const activeTickets = ticketsCopy
      .filter((a) => Number(a.status) === 1 && a.manage != null && a.manage.userId === userId);
    waitingTickets.push(...activeTickets);
    
    // find tickets that are not served yet and are matching my services
    const serviceWaitingTickets = ticketsCopy
      .filter((a) => (
        Number(a.status) === 0
        && services.includes(a.serviceId)
        && ( a.manage == null || ( a.manage != null && !Object.keys(a.manage).length) )
      ));
    waitingTickets.push(...serviceWaitingTickets);
    console.log(serviceWaitingTickets);

    // find tickets that were sent to my counterId
    const counterWaitingTickets = ticketsCopy
      .filter((a) => (
        Number(a.status) === 0
        && a.manage != null
        && a.manage.supportDeskId === supportDeskId
      ));
    waitingTickets.push(...counterWaitingTickets);

    // find tickets that were sent to my userId
    const userWaitingTickets = ticketsCopy
      .filter((a) => (
        Number(a.status) === 0  
        && a.manage != null
        && a.manage.userId === userId
      ));
    waitingTickets.push(...userWaitingTickets);
    // sort array by created in ascending order
    waitingTickets.sort((a, b) => a.created - b.created);
    // get priority customers first and filter out null objects using boolean
    let waitingPriorityCustomers = waitingTickets.filter( a => a.priority ? a : null ).filter(Boolean)
    // sort priority customers by listOrder in descending order
    waitingPriorityCustomers.sort((a, b) => b.priority - a.priority);
    // remove the waitingPriorityCustomers from waitingTickets
    let waitingNonPriorityTickets = waitingTickets.filter( a => !a.priority );
    // combine the priority and non priority tickets
    return [...waitingPriorityCustomers, ...waitingNonPriorityTickets];
}