import React from 'react';
import { connect } from 'react-redux'
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { PubSubKeys, TicketStatuses } from '../../../../store/constants'
import { callTicket } from '../../../../store/actions/ticketsActions'

import CalledTicketNumber from './CallTicket/CalledTicketNumber'

// components

class CallTicket extends React.Component {
    manageTicket(volume){
        const { authenticatedUser, supportDesks, supportDeskId } = this.props
        const action = 'callTicket'
        const callType = 'next'
        const supportDesk = supportDesks.find( data => data.id === supportDeskId)
        console.log(supportDesks, supportDesk, supportDeskId);

        // create new array from authenticatedUser
        const user = JSON.parse(JSON.stringify(authenticatedUser));
        delete user.accessToken;
        delete user.refreshToken;
        delete user.branchId;
        delete user.email;
        delete user.status;

        const ticket = {
            authenticatedUser: user,
            sessionStart: dayjs().unix(),
            volume,
            status: TicketStatuses[action],
            callType //next is to call the next available ticket or recall an active ticket : direct is to call ticket by its number
        }

        ticket.manage = {
            supportDesk,
            userId: authenticatedUser.id,
        }
        
        const payload = {
            ...PubSubKeys[action],
            msg: {
                ticket
            }
        }
        this.props.callTicket(this.props.hostname, payload)
    }

    render(){

        return (
            <div className="home-left-content2-item-full item1">
                <CalledTicketNumber />
                <div className="call" onClick={ () => this.manageTicket(1) }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="61" height="60" fill="none" viewBox="0 0 61 60">
                        <circle cx="30.5" cy="17.5" r="14.5" stroke="#fff" strokeWidth="4.287"/>
                        <path stroke="#fff" strokeWidth="4.287" d="M58 60c0-15.464-12.312-28-27.5-28S3 44.536 3 60"/>
                    </svg>
                    <h4>Call</h4>
                </div>
                <div className="mute" onClick={ () => this.manageTicket(0) }>
                    <ion-icon name="volume-mute-outline"></ion-icon>
                    Silent Call
                </div>
            </div>
        )
    }
}


CallTicket.propTypes = {
    // CallTicket: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    const { auth, tickets, services, sessions} = state
    return {
        hostname: auth.hostname,
        supportDeskId: auth.supportDeskId,
        tickets: tickets.tickets,
        activeTicketId: tickets.activeTicketId,
        supportDesks: services.supportDesks,
        sessions: sessions.sessions,
        authenticatedUser: auth.authenticatedUser,
    }
}

export default connect(mapStateToProps, { callTicket })(CallTicket);