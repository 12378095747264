import { 
  LISTEN_USERS_START,
  LISTEN_USERS_UPDATE,
  LISTEN_USERS_CREATE,
  LISTEN_USERS_DELETE,
  FETCH_USERS,
  RESET_USER,

} from '../actions/types';

const initialState = {
  listenUsersStart: 0,
  users: [],
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case LISTEN_USERS_START:
      return {
        ...state,
        listenUsersStart: action.payload
      };
    case LISTEN_USERS_CREATE:
      return {
        ...state,
        users: ([ ...state.users, action.payload ]).filter((data, index, array) => array.findIndex( data2 => ( data2.id === data.id ) ) === index ).sort((a, b) => a.name.localeCompare(b.name))
      };
    case LISTEN_USERS_UPDATE:
      return {
        ...state,
        users: state.users.map(data => {
          if (data.id === action.payload.id) { return action.payload }
          return data;
        })
      };
    case LISTEN_USERS_DELETE:
      return {
        ...state,
        users: state.users.filter(data => data.id !== action.payload.id )
      };
    case FETCH_USERS:
      return {
        ...state,
        users: action.payload.sort((a, b) => a.name.localeCompare(b.name))

      };
    
    default:
      return state;
  }
}